import {apiFetchGet, apiFetchPost} from "@/api/handler"
import translate_ca_data, {createFirstNode} from "@/store_vuex/handlers/translate_ca_data"
import calculateFamilyTreeProgress from "@/store_vuex/handlers/calculateFamilyTreeProgress"
import translate_ta_data, {importF3} from "@/store_vuex/handlers/translate_ta_data"

const state = () => ({
  data: null,
  ca_data: null,
  progress_data: {}
})

const actions = {
  async getData({dispatch, commit, rootState}) {
    let ca_data = await fetchCaData()
    if (!ca_data || ca_data.length === 0) ca_data = [createFirstNode(rootState.profile.info)]
    commit("mutateCaData", ca_data)
    const ta_data = translate_ca_data(ca_data)
    return dispatch("updateState", ta_data)
  },
  updateState({commit, dispatch}, family_tree_json) {
    commit("mutateData", family_tree_json)
    commit("mutateProgressData", calculateFamilyTreeProgress(family_tree_json))

    dispatch("refine_results/flushData", null, {root: true})
  },
  async saveData({state, commit, dispatch}, family_tree_json) {
    const ta_data = JSON.parse(JSON.stringify(family_tree_json)),
      ca_data = JSON.parse(JSON.stringify(state.ca_data)),
      updated_ca_data = translate_ta_data(ta_data, ca_data),
      updated_ta_data = translate_ca_data(ca_data)

    dispatch("updateState", updated_ta_data)
    commit("mutateCaData", updated_ca_data)
    apiFetchPost("/api/family-tree", {family_tree_json: updated_ta_data}, {obj: true});  // to save old format
    return apiFetchPost("/api/ca/family-tree", {family_tree_json: {data: updated_ca_data, version: "1.0"}}, {obj: true});
  },

  async getDataNoCA({dispatch, commit, rootState}) {
    return apiFetchGet("/api/family-tree")
      .then(response => response.json())
      .then(data => {if (data.family_tree_json) return dispatch("updateState", data.family_tree_json)})
      .finally(() => {if (!rootState.family_tree.data) dispatch("updateState", {})})
  },
  async saveDataNoCa({state, commit, dispatch}, family_tree_json) {
    const ta_data = JSON.parse(JSON.stringify(family_tree_json)); dispatch("updateState", ta_data);
    return apiFetchPost("/api/family-tree", {family_tree_json: ta_data}, {obj: true});  // to save old format
  }
};

actions.getData = actions.getDataNoCA; actions.saveData = actions.saveDataNoCa;

const getters = {}

const mutations = {
  mutateData(state, new_value) {
    state.data = new_value;
  },
  mutateCaData(state, new_value) {
    state.ca_data = new_value;
  },
  mutateProgressData(state, new_value) {
    state.progress_data = new_value;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}


function fetchCaData() {
  return apiFetchGet("/api/ca/family-tree").then(r => r.json()).then(data => {
    return data.family_tree_json ? data.family_tree_json.data : []
  })
}
