<template>
  <Modal :show_modal="$store.state.auth.modal_open" :closeModal="()=>$store.dispatch('auth/changeModalStatus', false)"
         :width="'null'" :custom_style="{close_btn: {color: $store.state.auth.form_type === 1 ? '#fff' : null, transform: 'translate(2px,-10px)'}, main_cont: {padding: '0'}}">
    <Login v-if="$store.state.auth.form_type === 1" />
    <template v-else-if="$store.state.auth.form_type === 2">
      <Registration :close="()=>$store.dispatch('auth/changeModalStatus', false)" :toLogin="()=>$store.dispatch('auth/switchFormType', 1)" />
    </template>
  </Modal>
</template>

<script>
import Login from "@/components/Auth/Login";
import Modal from "@/components/Elements/Modal"
import Registration from "@/components/Auth/Registration"

export default {
  name: "AuthModal",
  components: {Registration, Modal, Login}
};
</script>

<style scoped></style>
