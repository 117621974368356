import {apiFetchDelete, apiFetchGet, apiFetchPost} from "@/api/handler"

const state = () => ({
  ordered: false,
  created: false,
  result: false,
  order_failed: false
})

const actions = {
  getData({dispatch}) {
    return apiFetchGet("/api/family-tree")
      .then(response => response.json())
      .then(data => {
        const family_tree_json = data.family_tree_json || {};
        dispatch("updateState", family_tree_json)
      });
  },
  updateState({commit, dispatch}, status) {
    commit("mutateState", status)
  },
  order({commit, dispatch}, lang) {
    const new_report_state = JSON.parse(JSON.stringify(this.state));
    new_report_state.ordered = true;
    new_report_state.created = false;
    commit("mutateState", new_report_state)

    let form_data = new FormData();
    form_data.append("lang", lang);

    return apiFetchPost("/api/portal/free-report", form_data)
      .then(response => {
        if (response.status !== 200) {
          commit("mutateOrderFailed", true)
          throw "api rejects the call";
        }
      })
      .finally(() => dispatch("profile/getInfo", null, {root: true}));
  },
  deleteForTests() {
    return apiFetchDelete("/api/portal/free-report")
      .catch(reason => console.log(reason))
  }
}

const getters = {
  report_url() {
    return () => apiFetchGet("/api/portal/free-report")
      .then(response => {
        if (response.status !== 200) throw "no report for you";
        else return response;
      })
      .catch(reason => console.log(reason))
  }
}

const mutations = {
  mutateState(state, status) {
    state.ordered = status.ordered
    state.created = status.created
    state.result = status.result
  },
  mutateOrderFailed(state, new_value) {
    state.order_failed = new_value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
