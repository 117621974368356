<template>
  <div id="app" :key="$store.state.core.rerender" :data-lang="$store.state.lang.value">
    <template v-if="$store.state.core.initialized === 1">
      <NavBar></NavBar>
      <div id="app-body">
        <router-view name="root" />
      </div>
      <Footer></Footer>
      <AuthModal></AuthModal>
      <UnavailableModal />
      <Survey_Float />
    </template>

    <template v-else></template>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import NavBar from "./components/Core/navbar/NavBar";
import AuthModal from "./views/auth/AuthModal";
import Footer from "./components/Core/Footer";
import Survey_Float from "@/components/web/other/Survey_Float"
import UnavailableModal from "./views/web/UnavailableModal"

export default {
  name: "App",
  components: {UnavailableModal, Survey_Float, Footer, AuthModal, NavBar }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,800&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

#app {
  background-color: rgb(4, 43, 93);
}

#app-body {
}

#app h1,
#app h2 {
  font-weight: 800;
}

#app h3,
#app h4,
#app h5,
#app h6 {
  font-weight: 600;
}

a {
  text-decoration: underline;
  color: currentColor;
}

a:hover {
  color: #007bff;
}

.btn.ta_disabled {
  background-color: gray!important;
  border: none;
  color: #fff;
}

.btn {
  border-radius: 50px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.button_slide {
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #fffff3;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.button_slide:hover {
  box-shadow: inset 400px 0 0 0 #fffff3;
  color: rgb(4, 43, 93) !important;
}

.button_slide_blue {
  cursor: pointer;
  box-shadow: inset 0 0 0 0 rgb(4, 43, 93);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.btn_blue {
  background-color: rgb(4, 43, 93);
  color: #fff;
}

.button_slide_blue:hover {
  box-shadow: inset 400px 0 0 0 rgb(4, 43, 93);
  color: #fffff3 !important;
}

.button_slide_white {
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.button_slide_white:hover {
  box-shadow: inset 400px 0 0 0 #fff;
  color: rgb(4, 43, 93) !important;
}

.blue_bg {
  background-color: rgb(4, 43, 93);
  color: #fffff3;
}

.yellow_bg {
  background-color: #fffff3;
  color: rgb(4, 43, 93);
}

.small-caps {
  font-size: .7em;
  text-transform: uppercase;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

/* fancy inputs */

.inputContainer {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 20px auto 0;
  background-color: transparent;
  margin-left: 0;
}

.inputContainer.country {
  width: 50%;
  text-align: left;
}

.inputContainer .label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #999;
  pointer-events: none;

  transform-origin: 0 0;
  transition: all 0.2s ease;
}

.inputContainer label {
  width: 100%;
}

.inputContainer .border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #dee2e6;
  transform-origin: 0 0;
  transition: all 0.15s ease;
  border: 0 !important;
}

.inputContainer input,
.inputContainer select {
  width: 99%;
  border: 0;
  height: 39px;
  font-size: 16px;
  /*border-bottom: 1px solid #999;*/
  background: none;
  color: rgb(4, 43, 93);
  transition: all 0.15s ease;
}

.inputContainer select {
  -webkit-appearance: none;
  outline: 0;
}

.inputContainer input:hover,
.inputContainer select:hover {
  background: rgba(34, 50, 84, 0.01);
}

.inputContainer input:focus,
.inputContainer select:focus {
  background: none;
  outline: none;
}

.inputContainer input:focus + span,
.inputContainer select:focus + span,
.inputContainer select.has_value + span,
.inputContainer input.has_value + span {
  color: rgb(4, 43, 93);
  transform: translateY(-26px) scale(0.75);
}

.inputContainer input:focus + span + .border,
.inputContainer select:focus + span + .border {
  background-color: rgb(4, 43, 93);
}

/* </end> fancy inputs */

#app .mobile-show {
  display: none;
}
#app .mobile-hide {
  display: block;
}

@media (max-width: 576px) {
  #app .mobile-hide {
    display: none;
  }

  #app .mobile-show {
    display: initial;
  }
}

@media (min-width: 576px) {
  #app h2 {
    font-size: 2.1rem;
  }
  #app h5 {
    font-size: 1.12rem;
    line-height: 1.8;
  }
}

@media (min-width: 992px) {
  #app .container {
    max-width: 800px;
  }

  #app .wider-container {
    max-width: 900px !important;
  }
}

@media (min-width: 1200px) {
  #app .container {
    max-width: 850px;
  }

  #app .wider-container {
    max-width: 1060px !important;
  }
}
</style>
