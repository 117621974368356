<template>
  <div class="login_cont text-left ta-blue-color">
    <div style="height: 5px"></div>
    <div class="login_body">
      <template v-if="!check_email">
        <h2 class="mt-0 mb-3 ta-blue-color fw-800 mt-3">
          <span v-html="t.login"></span>
        </h2>
        <div class="help_text">
          {{ t.enter_email_text }}
        </div>
        <div>
          <form method="post" @submit="token_auth" class="" ref="login_form">
            <div>
              <div class="inputContainer">
                <label for="email" class="labelContainer">
                  <input @input="onFormFieldChange($event.target)" type="email" id="email" placeholder=" " name="email" required />
                  <span class="label">
                <span v-html="t.mail_address"></span>
              </span>
                  <span class="border"></span>
                </label>
              </div>
              <span v-for="err in errors.email" class="inp_error">{{ err }}</span>
            </div>

            <div class="text-center mt-4 pt-1">
              <button type="submit" class="btn btn_blue button_slide_white">
                <span v-html="t.log_in"></span>
              </button>
            </div>
          </form>
          <div>
            <div class="mt-3">
              <span class="help_text">{{ t.have_account }}</span>
            </div>
            <div class="text-center mt-3">
              <button
                  data-cy="register-link"
                  class="btn btn_blue button_slide_white"
                  @click="switchAuthFormType(2)"
              >
                <span v-html="t.register"></span>
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center ta-blue-color">
          <h2 class="mt-0 mb-3 red-color fw-800 mt-3">{{ t.check_email_h }}</h2>
          <div class="fs-14">
            <div class="mb-2">{{ t.check_email_p1 }}</div>
            <div class="mb-2">{{ email }}</div>
            <div>
              <span>{{ t.check_email_p2 }}</span><br>
              <span>{{ t.check_email_p3 }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h1": "UNIVERSAL LOGIN TO:",
        "login": "Login",
        "log_in": "LOGIN",
        "enter_email_text": "Enter the e-mail address associated with your account " +
            "and we’ll send you a link to your inbox, just click the link " +
            "and you’re all set.",
        "mail_address": "E-mail",
        "have_account": "Don't have a free account yet?",
        "register": "REGISTER",
        "check_email_h": "Check your inbox",
        "check_email_p1": "We just mailed a link to",
        "check_email_p2": "Click that link and you’ll be signed in.",
        "check_email_p3": "The link will be valid for 15 minutes only",
        "invalid_credentials": "Invalid Credentials"
      },
      "hr": {
        "h1": "UNIVERSAL LOGIN TO:",
        "login": "Prijava",
        "log_in": "PRIJAVA",
        "enter_email_text": "Upišite e-mail adresu koju ste koristili za izradu računa i poslat ćemo Vam poveznicu u " +
            "Vaš dolazni pretinac. Samo kliknite na poveznicu i Vaša prijava će biti spremna.",
        "mail_address": "E-mail adresa",
        "have_account": "Nemate besplatni račun?",
        "register": "REGISTRACIJA",
        "check_email_h": "Provjerite dolazni pretinac",
        "check_email_p1": "Upravo smo poslali poveznicu",
        "check_email_p2": "Klikom na tu poveznicu prijavit ćete se.",
        "check_email_p3": "Poveznica će biti valjana samo 15 minuta.",
        "invalid_credentials": "Nevaljani podaci"
      },
      "es": {
      }
    }
//</i18n>

import Modal from "@/components/Elements/Modal";
import {sendEmailLoginLink} from "@/api/auth";

export default {
  name: "Login",
  components: { Modal },
  data() {
    return {
      t: t[this.$lang],
      email: '',
      check_email: false,

      errors: {
        email: [],
      }
    };
  },
  mounted() {
    const vm = this;

    vm.prepopulateIfEmail(vm.$route.query)
  },
  methods: {
    onFormFieldChange(el) {
      const vm = this,
          field_name = el.name,
          value = el.value;

      vm.errors[field_name] = [];
      classed(el, "has_value", value !== "");
      if (field_name === 'email') vm.email = value

      function classed(el, class_name, add_class) {
        const re = new RegExp("(?:^|\\s)" + class_name + "(?!\\S)", "g");
        if (add_class && !el.className.match(re))
          el.className += " " + class_name;
        else if (!add_class) el.className = el.className.replace(re, "");
      }
    },
    token_auth(event) {
      const vm = this;

      event.preventDefault();
      const formData = new FormData(event.target)

      sendEmailLoginLink(formData).then(response => {
        response.json().then(data => {
          if (response.status === 200) {
            if (data.check_registration_email === true) vm.check_registration_email = true;
            if (data.check_auth_email === true) vm.check_email = true;

          } else {
            Object.keys(vm.errors).forEach(k => (vm.errors[k] = []));
            Object.keys(data.errors).forEach(error_type => {
              let err_msgs = data.errors[error_type];
              for (let i = 0; i < err_msgs.length; i++) {
                Object.keys(t["en"]).forEach(k => {
                  if (t["en"][k] === err_msgs[i]) {
                    err_msgs[i] = vm.t[k];
                  }
                });
              }

              vm.errors[error_type] = err_msgs;
            });
          }
        });
      });
    },
    logout() {
      this.$store.dispatch("auth/logout")
    },

    closeAuthModal() {
      const vm = this;
      vm.$store.dispatch("auth/changeModalStatus", false);
    },
    switchAuthFormType(form_type) {
      const vm = this;
      vm.$store.dispatch("auth/switchFormType", form_type)
    },
    prepopulateIfEmail(query) {
      const vm = this;
      if (query.email) {
        const inp = vm.$el.querySelector("input[name='email']")
        inp.value = query.email
        vm.onFormFieldChange(inp)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login_cont {
  width: 450px;
  max-width: 100%;

  margin: auto;
  background-color: white;
  color: rgb(1, 1, 1);
  border-radius: 10px;
}

.login_head {
  padding: 20px;
  color: #fff;
  text-align: center;
  font-size: 14px;

  .row {
    & > div {
      width: 50%;
      //@media (max-width: 400px) {
      //  width: 100%;
      //  text-align: center!important;
      //  margin-bottom: 5px;
      //}
    }
  }
}

.login_body {
  padding: 0 40px 30px;
}

.btn {
  padding: 10px;
  width: 75%;
  font-weight: 600;
}

.help_text {
  font-size: 13px;
}

.inp_error {
  display: block;
  color: red;
  font-size: 11px;
}

.login_footer {
  padding: 20px 30px;
  text-align: center;
  background-color: #ededed;
  line-height: 1;
}

.ta-blue-color {
  color: rgb(4,43,93);
}

@media (max-width: 576px) {
  .login_head {
    padding: 20px 15px;
  }
  .login_body {
    padding: 0 25px 30px;
  }
  .login_footer {
    padding: 20px 25px;
  }
}
</style>
