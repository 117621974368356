import router from "@/router"
import {app} from "@/main"

const state = () => ({
  value: 'en'
})

// actions
const actions = {
  change({ commit, state, rootState, dispatch }, lang) {
    if (state.value === lang) return
    commit("mutate", lang);
    setCookie("lang", lang, 365)
    if (rootState.auth.user_logged_in) dispatch("profile/changeProfileLang", lang, {root: true});
  },
  getAppLang({ commit, rootState, dispatch }) {
    const route = router.currentRoute.value;
    if (route.params.lang) {
      commit("mutate", route.params.lang)
    } else if (rootState.auth.user_logged_in) {
      return dispatch("profile/getInfo", null, {root: true})
        .then(() => {
          const lang = (rootState.profile.info || {}).language || "en"; // TODO: remove ||
          commit("mutate", lang)
        });
    } else if (getCookie("lang")) {
      commit("mutate", getCookie("lang"))
    } else {
      return new Promise(resolve => {
        commit("mutate", "en")
        resolve("");
      });
    }
  }
}

// mutations
const mutations = {
  mutate(state, lang) {
    app.config.globalProperties.$lang = lang
    state.value = lang
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
