import {TokenService} from "@/services/storage.service"

export function apiFetchGet(url, opt) {
  return fetch(process.env.VUE_APP_BASEURL + url, {
    method: "GET",
    credentials: "include",
    headers: getAuthHeader()
  })
}

export function apiFetchGetNoAuth(url) {
  return fetch(process.env.VUE_APP_BASEURL + url, {
    method: "GET"
  })
}

export function apiFetchPost(url, data, opt) {
  return fetch(process.env.VUE_APP_BASEURL + url, {
    method: "POST",
    credentials: "include",
    headers: getAuthHeader(),
    body: (opt && opt.obj) ? setupFormData(data) : data
  })
}

export function apiFetchPostNoAuth(url, data, opt) {
  return fetch(process.env.VUE_APP_BASEURL + url, {
    method: "POST",
    body: (opt && opt.obj) ? setupFormData(data) : data
  })
}

export function apiFetchDelete(url, data, opt) {
  return fetch(process.env.VUE_APP_BASEURL + url, {
    method: "DELETE",
    credentials: "include",
    headers: getAuthHeader(),
    body: (opt && opt.obj) ? setupFormData(data) : data
  })
}

export function apiFetchPatch(url, data, opt) {
  return fetch(process.env.VUE_APP_BASEURL + url, {
    method: "PATCH",
    credentials: "include",
    headers: getAuthHeader(),
    body: (opt && opt.obj) ? setupFormData(data) : data
  })
}

function setupFormData(obj) {
  let form_data = new FormData();

  for (let k in obj) {
    if (!obj.hasOwnProperty(k)) continue
    const str_value = typeof obj[k] === "string" ? obj[k] : JSON.stringify(obj[k])
    form_data.append(k, str_value);
  }
  return form_data
}

function getAuthHeader() {
  let headers = new Headers();
  headers.append("Authorization", "Token " + TokenService.getToken());
  return headers
}

