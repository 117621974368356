<template>
  <div>
    <div class="root_cont" v-if="!cookie_accepted">
      <div class="container text-center">
        <span>{{ t.t1 }}</span>&nbsp;
        <router-link :to="{name: 'cookie_policy'}">{{ t.learn_more }}</router-link>&nbsp;
        <a href="javascript:void(0)" @click="acceptCookies">{{ t.accept_cookies }}</a>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "t1": "Terra Adriatica uses cookies to improve your site experience.",
        "learn_more": "Learn more.",
        "accept_cookies": "Accept cookies."
      },
      "hr": {
        "t1": "Terra Adriatica koristi kolačiće za poboljšanje Vašeg iskustva na stranici.",
        "learn_more": "Saznaj više.",
        "accept_cookies": "Prihvati kolačiće."
      },
      "es": {

      }
    }
//</i18n>

export default {
  name: "Cookies",
  data() {
    return {
      t: t[this.$lang],
      cookie_accepted: false
    };
  },
  mounted() {
    const vm = this;

    vm.checkCookie();
  },
  methods: {
    acceptCookies() {
      const vm = this;

      vm.setCookie("cookie_accepted", "true", 180);

      vm.checkCookie();
    },
    checkCookie() {
      const vm = this;

      vm.cookie_accepted = vm.getCookie("cookie_accepted") === "true";
    },
    setCookie(name, value, days) {
      const vm = this;
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const vm = this;

      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    eraseCookie(name) {
      const vm = this;
      document.cookie = name + "=; Max-Age=-99999999;";
    }
  }
};
</script>

<style scoped>
.root_cont {
  position: fixed;
  width: 100%;
  background-color: rgba(36, 36, 36, 0.9);
  padding: 20px;
  bottom: 0;
  z-index: 10;
}
</style>
