import { createApp } from 'vue'
import App from "./App";
import router from "./router";
import store from './store_vuex'
import registerGlobalSvgIcons from "./components/Elements/SvgIcons.js";

export const app = createApp(App)
if (process.env.NODE_ENV === 'development') window.__app__ = app

registerGlobalSvgIcons(app)

app.use(store)
app.use(router)
app.mount('#app')

