export default function calculateFamilyTreeProgress(family_tree) {
  const ft_values = Object.values(family_tree),
    non_empty_ft_values = ft_values.filter(d => Object.keys(d).length > 0),
    progress = Math.floor((non_empty_ft_values.length / ft_values.length) * 100),
    enough_data = progress > 40,
    is_all_members = non_empty_ft_values.length === ft_values.length,
    is_all_ggp_names = isAllGGPNames(),
    is_all_birth_and_last_place = isSomeDetails();

  return {
    progress,
    enough_data,
    is_all_members,
    is_all_ggp_names,
    is_all_birth_and_last_place
  }

  function isAllGGPNames() {
    if (!family_tree) return false
    return ["ff", "fm", "mm", "mf"].every(rel_type => {
      return !!(family_tree[rel_type] && family_tree[rel_type].fathers_name)
    })
  }

  function isSomeDetails() {
    return ft_values.every(datum => {
      return !!datum.birth_place && !!datum.address_of_residence
    })
  }
}