<template>
  <div class="app_nav_mobile container position-relative" data-cy="nav_mobile">
    <Hamburger :nav_height_class="nav_height_class" :toggleMobileMenu="toggleMobileMenu" :mobile_menu_open="mobile_menu_open" />

    <div class="tabs-cont d-inline-block" :class="{ open: mobile_menu_open }">
      <template v-for="tab in is_portal ? portal_routes : web_routes">
        <div
          :class="{ active: $store.state.core.tab_active === tab }"
          @click="changeTab(tab)"
        >
          <PortalTab v-if="is_portal" :name="tab" :label="t[tab]" />
          <WebTab v-else :name="tab" :label="t[tab]" />
        </div>
      </template>
      <hr class="menu_hr" />
      <div class="lang-cont">
        <template v-for="(lang, i) in [['hr', 'HRVATSKI'], ['en', 'ENGLISH']]">
          <div @click="() => changeLang(lang[0])">
            <WebTab :label="lang[1]" />
          </div>
        </template>
      </div>
      <hr class="menu_hr" />
      <template v-if="user_logged_in === true">
        <div
          :class="{ active: $route.path.indexOf('portal') !== -1 }"
          @click="changeTab('portal_profile')"
        >
         <WebTab :label="t.my_account" />
        </div>
        <div @click="logout">
          <WebTab :label="t.logout" />
        </div>
      </template>
      <template v-else>
        <div
          :class="{ active: $store.state.core.tab_active === 'login' }"
          @click="openLogin()"
          data-cy="login-tab"
        >
          <WebTab :label="t.login" />
        </div>
      </template>
      <div class="pb-2"></div>
    </div>
  </div>
</template>

<script>

const t =
  //<i18n>
{
  "en": {
    "home": "HOME",
    "locksmith": "LOCKSMITH",
    "reports": "REPORTS",
    "technology": "TECHNOLOGY",
    "blog": "BLOG",
    "about": "ABOUT",
    "faq": "FAQ",
    "login": "LOGIN",
    "logout": "Logout",
    "my_account": "My account",
    "portal_profile": "PROFILE",
    "portal_family_tree": "FAMILY TREE",
    "portal_locksmith": "LOCKSMITH",
    "portal_my_locksmith": "MY LOCKSMITH",
    "portal_reports": "REPORTS",
    "portal_refine_results": "REFINE RESULTS"
  },
  "hr": {
    "home": "POČETNA",
    "locksmith": "LOCKSMITH",
    "reports": "IZVJEŠĆA",
    "technology": "TEHNOLOGIJA",
    "blog": "BLOG",
    "about": "O NAMA",
    "faq": "FAQ",
    "login": "PRIJAVA",
    "logout": "Odjava",
    "my_account": "Moj račun",
    "portal_profile": "PROFIL",
    "portal_family_tree": "OBITELJSKO STABLO",
    "portal_locksmith": "LOCKSMITH",
    "portal_my_locksmith": "MOJ LOCKSMITH",
    "portal_reports": "IZVJEŠĆA",
    "portal_refine_results": "POBOLJŠAJ REZULTATE"
  },
  "es": {
    "home": "INICIO",
    "locksmith": "LOCKSMITH",
    "technology": "TECNOLOGÍA",
    "blog": "BLOG",
    "about": "SOBRE TA",
    "faq": "PMF",
    "login": "INICIAR SESIÓN",
    "logout": "Cerrar sesión",
    "my_account": "Mi cuenta",
    "portal_profile": "PERFIL",
    "portal_family_tree": "ÁRBOL GENEALÓGICO",
    "portal_locksmith": "LOCKSMITH",
    "portal_reports": "INFORMES",
  }
}
//</i18n>

export default {
  name: "NavBarMobile",
  props: [
    "changeTab",
    "openLogin",
    "changeLang",
    "logout",
    "user_logged_in",
    "is_mobile_nav",
    "web_routes",
    "nav_height_class",
    "mobile_menu_open",
    "toggleMobileMenu"
  ],
  components: {Hamburger: Hamburger(), PortalTab: PortalTab(), WebTab: WebTab()},
  data() {
    return {
      t: t[this.$lang],
    };
  },
  mounted() {},
  computed: {
    is_portal() {
      return this.$route.matched.some(d => d.name === "portal_home")
    },
    portal_routes() {
      const vm = this;
      return [
        "portal_profile",
        "portal_family_tree",
        vm.$store.state.refine_results.access ? "portal_refine_results" : null,
        "portal_reports",
        "portal_locksmith",
        (vm.$store.state.profile.info && vm.$store.state.profile.info.locksmith.contract_signed) ? "portal_my_locksmith" : null,
      ].filter(v => v)
    }
  },
  methods: {}
};

function WebTab() {

  return {
    props: ['label'],
    render() {
      return (
        <div class="tab row">
          <div class="col-10">
            <a class="router-link">{this.label}</a>
          </div>
          <div class="side_arrow_cont col-2">
            <img src="/static/images/Arrow.svg" alt="" class="side_arrow"/>
          </div>
        </div>
      )
    }
  }
}

function PortalTab() {
  const Icon = _Icon()
  return {
    props: ['name', 'label'],
    render() {
      return (
        <div class="tab row">
          <div class="col-10">
            <div class={`${this.name} portal_tab`}>
              <div class="tab_icon">
                <Icon name={this.name}/>
              </div>
              <div class="tab_text">
                <a class="router-link">{this.label}</a>
              </div>
            </div>
          </div>
          <div class="side_arrow_cont col-2">
            <img src="/static/images/Arrow.svg" alt="" class="side_arrow"/>
          </div>
        </div>
      )
    }
  }

  function _Icon() {
    return {
      props: ['name'],
      render() {
        return (
            <div class="v-middle">
              {
                this.name === 'portal_profile' ? <svg-profile-icon class="icon"/>
                : this.name === 'portal_family_tree' ? <svg-family-tree class="icon"/>
                : this.name === 'portal_reports' ? <svg-reports-icon class="icon"/>
                : this.name === 'portal_refine_results' ? <svg-refine-results class="icon"/>
                : this.name === 'portal_locksmith' ? <svg-locksmith-icon class="icon"/>
                : this.name === 'portal_my_locksmith' ? <svg-my-locksmith-icon class="icon"/>
                : ''
              }
            </div>
        )
      }
    }
  }
}

function Hamburger() {
  return {
    props: ['nav_height_class', 'toggleMobileMenu', 'mobile_menu_open'],
    render(h) {
      return (
        <div class="h-trans" class={this.nav_height_class}>
          <div class="v-middle">
            <div
                data-w="ta_logo_mobile"
                class="ta_logo d-inline-block"
                onClick={() => this.$router.push({name: 'home'})}
            >
              <img src="/static/images/TAlogoNovi.svg" alt=""/>
            </div>
            <div
                id="nav-icon"
                onClick={this.toggleMobileMenu}
                class={this.mobile_menu_open ? 'open' : ''}
                style={{visibility: (this.$route.meta && this.$route.meta.hide_tabs) ? 'hidden' : null}}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )
    }
  }
}

</script>

<style lang="scss">
.app_nav_mobile {
  .v-middle {
    position: absolute;
    top: 58%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }

  .tabs-cont {
    vertical-align: bottom;
    padding-left: 14px;
  }

  .tab a {
    font-weight: 600;
    font-size: 16px;
    color: #fffff3;
  }

  .tab.active a {
  }

  .tab {
    padding: 10px 10px 10px 20px;
    width: 100%;
    cursor: pointer;
  }

  .tabs-cont {
    display: block;
    position: absolute;
    background-color: rgb(4, 43, 93);
    right: 0;
    padding-left: 0;

    height: auto;
    max-height: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: 1000ms;

    width: 80%;
    max-width: 450px;
    z-index: 3;
  }

  .tabs-cont.open {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .ta_logo {
    width: 200px;
    margin-left: 10px;
  }

  .side_arrow_cont {
    pointer-events: none;
    position: relative;
  }

  .side_arrow {
    width: 17px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: max-content;
    transform: rotateZ(-90deg);
  }

  .menu_hr {
    background-color: #fffff3;
    margin: 10px 25px;
    opacity: 0.5;
    position: relative;
    left: -5px;
  }

  a {
    position: relative;
    text-decoration: none;
    padding-bottom: 8px;
  }

  a:before,
  a:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #fffff3;
  }

  a:before {
    opacity: 0;
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    transition: opacity 0s,
    -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
    transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s,
    -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  a:after {
    opacity: 0;
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
    transition: opacity 0.2s,
    -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s;
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .active a:after,
  a:hover:before,
  a:hover:after,
  a:focus:before,
  a:focus:after {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  a:hover:before,
  a:focus:before {
    transition: opacity 0.2s,
    -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s;
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  a:hover:after,
  a:focus:after {
    transition: opacity 0s 0.2s,
    -webkit-transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0s 0.2s;
    transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0s 0.2s,
    -webkit-transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  #nav-icon {
    width: 50px;
    height: 40px;
    display: inline-block;
    position: relative;
    margin: 5px;
    margin-right: 10px;
    float: right;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: scale(0.8) rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 2.5px;
    width: 100%;
    background: #fffff3;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  /* Icon 3 */

  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    top: 15px;
  }

  #nav-icon span:nth-child(4) {
    top: 30px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 15px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon.open span:nth-child(4) {
    top: 15px;
    width: 0%;
    left: 50%;
  }


  /*  portal  */
  .portal_tab {
    display: flex;
    position: relative;
  }

  .portal_tab .tab_icon {
    width: 30px;
  }

  .portal_tab .tab_text {
    width: calc(100% - 30px);
    margin-left: 6px;
  }

  .icon {
    display: inline-block;
    position: relative;
    margin-bottom: 3px;
  }

  .portal_profile .icon {
    width: 20px;
    top: -1px;
  }

  .portal_family_tree .icon {
    top: 1px;
    left: -2px;
    width: 28px;
  }

  .portal_locksmith .icon {
    width: 24px;
    top: 3px;
  }

  .portal_my_locksmith .icon {
    width: 24px;
    top: 3px;
  }

  .portal_refine_results .icon {
    width: 19px;
    left: 4px;
  }

  .portal_reports .icon {
    width: 19px;
    left: 4px;
  }
  /*  portal  */
}
</style>
