import {TokenService} from "@/services/storage.service"
import {apiFetchGet, apiFetchPostNoAuth} from "@/api/handler"

export function sendEmailLoginLink(formData) {
  return apiFetchPostNoAuth("/api/auth/send-email-login-link", formData)
}

export function authWithOneTimeToken(token, resend_link) {
  const formData = new FormData();
  formData.set("token", token)
  if (resend_link) formData.set("resend_link", "1")

  let url = "/api/auth/auth-with-one-time-token";
  if (process.env.NODE_ENV === "development") url += "?site_code=ta";  // testing
  return apiFetchPostNoAuth(url, formData)
}

export function register(event) {
  const formData = new FormData(event.target);
  addFirstVisit(formData)
  addLandingPage(formData)
  addFamilyTree(formData)

  let url = "/api/auth/register";
  if (process.env.NODE_ENV === "development") url += "?site_code=ta";  // testing
  return apiFetchPostNoAuth(url, formData)
}

export function checkAuthStatusApi() {
  if (!TokenService.getToken()) return Promise.resolve({status: 404});
  return apiFetchGet("/api/auth/check-auth-status")
}




function addFirstVisit(formData) {
  formData.set('first_visit', getCookie('first_visit'))
}

function addLandingPage(formData) {
  if (getCookie('landing_page')) formData.set('landing_page', getCookie('landing_page'))
}

function addFamilyTree(formData) {
  const data = {
    first_name: formData.get('first_name'),
    last_name: formData.get('last_name'),
    country_of_last_residence: formData.get('country_of_residence'),
  }
  formData.set("family_tree_json", JSON.stringify(f3.handlers.createTreeDataWithMainNode({data})))
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}