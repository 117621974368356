<template>
  <div
    id="nav_root"
    class="nav_root h-trans"
    :class="{
    landing_page_nav: is_landing_page,
    is_landing_page_reg,
    [nav_height_class]: true,

  }"
  >
    <nav class="nav_desktop h-trans" :class="{[nav_height_class]: true}">
      <NavBarDesktop
        :changeTab="changeTab"
        :openLogin="openLogin"
        :changeLang="changeLang"
        :logout="logout"
        :user_logged_in="$store.state.auth.user_logged_in"
        :is_mobile_nav="is_mobile_nav"
        :web_routes="web_routes"
      />
    </nav>

    <nav
      class="nav_mobile"
    >
      <LandingPageTopParallaxMaybe :is_nav="true" />
      <NavBarMobile
        :changeTab="changeTab"
        :openLogin="openLogin"
        :changeLang="changeLang"
        :logout="logout"
        :user_logged_in="$store.state.auth.user_logged_in"
        :is_mobile_nav="is_mobile_nav"
        :web_routes="web_routes"
        :nav_height_class="nav_height_class"
        :mobile_menu_open="mobile_menu_open"
        :toggleMobileMenu="toggleMobileMenu"
      />
    </nav>
  </div>
</template>

<script>
import Modal from "../../Elements/Modal";
import Login from "../../Auth/Login";
import NavBarDesktop from "./NavBarDesktop";
import NavBarMobile from "./NavBarMobile";
import LandingPageTopParallax from "../../Elements/LandingPageTopParallax"

export default {
  name: "NavBar",
  components: {LandingPageTopParallaxMaybe: LandingPageTopParallax, NavBarMobile, NavBarDesktop, Login, Modal },
  data() {
    return {
      mobile_menu_open: false,
      scrolled_down: false,
      is_landing_page: false,
      is_landing_page_reg: false,
      bg: {},
      login_modal: false,
      register_modal: false,

      web_routes: ["home", "locksmith", "reports", "technology", "about", "blog", "faq"]
    };
  },
  mounted() {
    const vm = this;

    (function setupOnScroll() {
      window.onscroll = function() {
        const y_off = window.pageYOffset;
        if (vm.scrolled_down) vm.scrolled_down = y_off > 60;
        else vm.scrolled_down = y_off > 120;
      };
    })();

    vm.isLandingPageUpdate();
  },
  computed: {
    is_mobile_nav() {
      const vm = this;
      if (vm.is_landing_page) return true
      else return vm.$store.state.responsive.window_width < 1200;
    },
    nav_height_class() {
      const vm = this;
      let h;
      if (!vm.is_mobile_nav) h = vm.scrolled_down ? 80 : 100;
      else h = vm.scrolled_down ? 70 : 80;
      return "h-{}px".replace("{}", h)
    }
  },
  methods: {
    changeTab(route_name) {
      const vm = this;
      if (vm.mobile_menu_open) vm.mobile_menu_open = false;
      vm.$router.push({ name: route_name });
    },
    openLogin() {
      const vm = this;

      if (vm.mobile_menu_open) vm.mobile_menu_open = false;
      vm.$store.dispatch("auth/openAuth", 1);
    },
    changeLang(lang) {
      const vm = this;

      if (vm.mobile_menu_open) vm.mobile_menu_open = false;

      vm.$store.dispatch("lang/change", lang);
      vm.$router.push({
        name: vm.$route.name,
        params: { lang, rerender: true }
      });
    },
    toggleMobileMenu() {
      const vm = this;

      vm.mobile_menu_open = !vm.mobile_menu_open
    },
    logout() {
      const vm = this;

      vm.$store.dispatch("auth/logout")
      vm.changeTab("home");
    },
    isLandingPageUpdate() {
      const vm = this;

      const curr_path = vm.$route.path,
        curr_path_end = curr_path.split("/")[curr_path.split("/").length-1]
      vm.is_landing_page = (this.$route.meta && this.$route.meta.is_landing_page)
      vm.is_landing_page_reg = (this.$route.meta && this.$route.meta.is_registration)
    }
  },
  watch: {
    '$route' (to, from) {
      const vm = this;
      vm.isLandingPageUpdate()
    }
  }
};
</script>

<style scoped>
.nav_root {
  transition: height 500ms;
}

nav {
  transition: height 500ms;
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: rgb(4, 43, 93);
  color: #fffff3;
}

nav.nav_desktop {
  overflow-y: hidden;
}

.nav_desktop {
  display: initial;
}
.nav_mobile {
  display: none;
}

.landing_page_nav .nav_desktop {
  display: none;
}
.landing_page_nav .nav_mobile {
  display: initial;
}

.landing_page_nav .nav_mobile {
  background-color: rgba(255,255,255,0);
}

.landing_page_nav.is_landing_page_reg .nav_mobile {
  background-color: rgb(4, 43, 93);
}

.landing_page_nav.is_landing_page_reg .image_parallax_wrapper {
  display: none;
}

.landing_page_nav .image_parallax_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landing_page_nav .image_parallax {
  position: absolute;
  background-position-x: center;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

svg {display: none}

@media (max-width: 1200px) {
  .nav_desktop {
    display: none;
  }

  .nav_mobile {
    display: initial;
  }
}
</style>

<style>
  .h-trans {
    transition: height .3s;
  }

  .h-100px {
    height: 100px;
  }
  .h-80px {
    height: 80px;
  }
  .h-70px {
    height: 70px;
  }

  .ta_logo {
    cursor: pointer;
    transition: width .3s;
  }

  #nav_root.h-100px .ta_logo {
    width: 200px;
  }
  #nav_root.h-80px .ta_logo {
    width: 180px;
  }
  #nav_root.h-70px .ta_logo {
    width: 170px;
  }
</style>
