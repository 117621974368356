import {apiFetchGet, apiFetchPost} from "@/api/handler"
import {groupParcels, createDataByRels} from "@/store_vuex/handlers/refine_results.structureData"

const state = () => ({
  access: null,
  parcels: null,
  data:null,

  active_person: null,
  active_person_rel: null,
  active_parcel: null,

  regions: null,
  counties: null,
  owners: null,

  dont_know: {},
})

const actions = {
  checkAccess({commit}) {
    return apiFetchGet("/api/portal/refine-results/check-access")
      .then(r => r.json())
      .then(resp_json => commit("mutateAccess", resp_json.result))
  },
  async initialize({commit, dispatch, rootState}) {
    dispatch("updateActivePerson", rootState.family_tree.data["main"])
    dispatch("fetchRegions")
  },
  async fetchRegions({commit}) {
    apiFetchGet("/api/portal/refine-results/region-fetch").then(r => r.json()).then(d => commit("mutateRegions", d.results))
  },
  async fetchCounties({commit}) {
    apiFetchGet("/api/portal/refine-results/county-fetch").then(r => r.json()).then(d => commit("mutateCounties", d.results))
  },
  async fetchOwners({commit}) {
    apiFetchGet("/api/portal/refine-results/owner-fetch").then(r => r.json()).then(d => commit("mutateOwners", d.results))
  },
  flushData({commit}) {
    commit("mutateRegions", null)
    commit("mutateCounties", null)
    commit("mutateOwners", null)
    commit("mutateParcels", null)
  },
  async updateRegions({commit, state}, selected) {
    const regions = JSON.parse(JSON.stringify(state.regions))
    regions.forEach(d => {d.odabran_regija = selected.includes(d.regijaid) ? 1 : 0})
    commit("mutateRegions", regions)
    commit("mutateCounties", null)
    commit("mutateOwners", null)
    commit("mutateParcels", null)
    commit("mutateDontKnow", {regions: false})

    await apiFetchPost("/api/portal/refine-results/region-update", {regions: selected}, {obj: true})
  },
  async updateCounties({commit, state}, selected) {
    const counties = JSON.parse(JSON.stringify(state.counties))
    counties.forEach(d => {d.odabran_odjel = selected.includes(d.odjelid) ? 1 : 0})
    commit("mutateCounties", counties)
    commit("mutateOwners", null)
    commit("mutateParcels", null)
    commit("mutateDontKnow", {counties: false})

    await apiFetchPost("/api/portal/refine-results/county-update", {counties: selected}, {obj: true})
  },
  async updateOwner({commit, state}, {datum, score}) {
    const owners = JSON.parse(JSON.stringify(state.owners))
    owners.forEach(d => {if (d.vlasnik === datum.vlasnik) d.score_vlasnik = score})
    commit("mutateOwners", owners)
    commit("mutateParcels", null)

    await apiFetchPost("/api/portal/refine-results/owner-update", {rel: datum.rel, owner: datum.vlasnik, score}, {obj: true})
  },
  updateDontKnow({commit, state}, {regions, counties}) {
    if (regions !== undefined) commit("mutateDontKnow", {regions})
    if (counties !== undefined) commit("mutateDontKnow", {counties})
  },
  async fetchData({commit, dispatch, state, rootState}) {
    const results = await apiFetchGet("/api/portal/refine-results/parcels-fetch").then(r => r.json())
    const grouped_parcels = Object.freeze(groupParcels(results.results))
    commit("mutateParcels", grouped_parcels)
  },
  updateParcelScore({commit, state}, {datum, score}) {
    commit("mutateScore", {datum, score})
    apiFetchPost("/api/portal/refine-results/score-update", {rel: datum.rel, scoreid: datum.scoreid, score}, {obj: true})
  },
  updateLocksmithInclude({commit, state}, {datum, value}) {
    commit("mutateLocksmithInclude", {datum, value})
    apiFetchPost("/api/portal/refine-results/score-update", {rel: datum.rel, scoreid: datum.scoreid, locksmith: value}, {obj: true})
  },
  updateActivePerson({commit, state, rootState}, datum) {
    commit("mutateActivePerson", datum)
    commit("mutateActivePersonRel", Object.keys(rootState.family_tree.data).find(k => rootState.family_tree.data[k] === datum))
    if (state.active_parcel) commit("mutateActiveParcel", null)
  },
  updateActiveParcel({commit}, datum) {
    commit("mutateActiveParcel", datum)
  },
}

const getters = {}

const mutations = {
  mutateAccess(state, new_value) {state.access = new_value},
  mutateParcels(state, new_value) {state.parcels = new_value},
  mutateData(state, new_value) {state.data = new_value},
  mutateScore(state, {datum, score}) {datum.score = score},
  mutateLocksmithInclude(state, {datum, value}) {datum.locksmith_include = value},
  mutateActivePerson(state, new_value) {state.active_person = new_value},
  mutateActivePersonRel(state, new_value) {state.active_person_rel = new_value},
  mutateActiveParcel(state, new_value) {state.active_parcel = new_value},
  mutateRegions(state, new_value) {state.regions = new_value},
  mutateCounties(state, new_value) {state.counties = new_value},
  mutateOwners(state, new_value) {state.owners = new_value},
  mutateDontKnow(state, new_value) {state.dont_know = Object.assign(state.dont_know, new_value)},
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
