<template>
  <div data-cy="nav_desktop">
    <div class="v-middle">
      <div class="container wider-container">
        <div class="h-middle">
          <div class="d-inline-block" style="width: 200px">
            <div
              class="ta_logo"
              data-w="ta_logo_desktop"
              @click="$router.push({ name: 'home' })"
            >
              <img src="/static/images/TAlogoNovi.svg" alt="" />
            </div>
          </div>
          <div class="tabs-cont d-inline-block position-relative" :style="{visibility: ($route.meta && $route.meta.hide_tabs) ? 'hidden' : null}">
            <div class="top-cont">
              <div class="lang-cont">
                <template v-for="(lang, i) in [['hr', 'HRVATSKI'], ['en', 'ENGLISH']]">
                  <div class="lang-opt-cont ml-2" @click="changeLang(lang[0])">
                    <span :class="['lang-opt', {active: $lang === lang[0]}]">{{ lang[1] }}</span>
                    <span class="lang-opt fake">{{ lang[1] }}</span>
                  </div>
                </template>
              </div>
              <div class="login_btn d-inline-block ml-4" :class="{ active: $route.path.indexOf('portal') !== -1 }">
                <span class="btn ta_disabled" v-if="user_logged_in === true" @click="changeTab('portal_profile')" data-cy="my_account-tab">
                  <span>{{ t.my_account }}</span>
                </span>
                <span class="btn ta_disabled" v-else @click="openLogin()" data-cy="login-tab">{{t.login}}</span>
              </div>
            </div>
            <template v-for="tab in web_routes">
              <div
                class="tab"
                :class="{ active: $store.state.core.tab_active === tab }"
              >
                <router-link :to="{ name: tab }">{{ t[tab] }}</router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const t =
  //<i18n>
  {
    en: {
      home: "HOME",
      locksmith: "LOCKSMITH",
      reports: "REPORTS",
      technology: "TECHNOLOGY",
      blog: "BLOG",
      about: "ABOUT",
      faq: "FAQ",
      login: "LOGIN",
      logout: "Logout",
      my_account: "My account"
    },
    hr: {
      home: "POČETNA",
      locksmith: "LOCKSMITH",
      reports: "IZVJEŠĆA",
      technology: "TEHNOLOGIJA",
      blog: "BLOG",
      about: "O NAMA",
      faq: "FAQ",
      login: "PRIJAVA",
      logout: "Odjava",
      my_account: "Moj račun"
    },
    es: {
      home: "INICIO",
      technology: "TECNOLOGÍA",
      blog: "BLOG",
      about: "SOBRE TA",
      faq: "PMF",
      login: "INICIAR SESIÓN",
      logout: "Cerrar sesión",
      my_account: "Mi cuenta"
    }
  };
//</i18n>


export default {
  name: "NavBarDesktop",
  props: [
    "changeTab",
    "openLogin",
    "changeLang",
    "logout",
    "user_logged_in",
    "is_mobile_nav",
    "web_routes"
  ],
  data() {
    return {
      t: t[this.$lang]
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.v-middle {
  position: absolute;
  top: 61%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.h-middle {
  width: max-content;
  margin: auto;
}

.tabs-cont {
  vertical-align: bottom;
  padding-left: 14px;
}

.tab {
  display: inline-block;
  padding: 5px 10px;
}

.tab a {
  font-weight: 600;
  font-size: 14px;
  color: #fffff3;
}

.tab.active a {
}

.btn {
  border: 1px solid #fffff3;
  font-size: 12px;
  color: #fffff3;
  font-weight: 600;
  padding: 3px 30px;

  cursor: pointer;
}

.login_btn {
  position: relative;
  height: 27px;
  transform: translate(0,4px);
}

.login_btn span.btn {
  white-space: nowrap;
}

.login_btn.active span.btn {
  background-color: #fffff3;
  color: rgb(4, 43, 93);
}

.top-cont {
  position: absolute;
  right: 3px;
  top: -26px;
  font-size: 12px;
  letter-spacing: 1px;
  transition: top .3s;
}

.lang-cont {
  display: inline-block;
  position: relative;
  top: 5px;
}

.lang-opt-cont {
  display: inline-block;
  position: relative;
}

.lang-opt {
  cursor: pointer;
  position: absolute;
  left: 2px;
}

.lang-opt.fake {
  position: static;
  font-weight: 800;
  opacity: 0;
}

.lang-opt-cont:hover .lang-opt {
  font-weight: 800;
  left: 0;
}

.lang-opt.active {
  font-weight: 800;
  left: 0;
}

a {
  position: relative;
  text-decoration: none;
  padding-bottom: 8px;
}

a:before,
a:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fffff3;
}

a:before {
  opacity: 0;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  transition: opacity 0s,
    -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
  transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s,
    -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

a:after {
  opacity: 0;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  transition: opacity 0.2s,
    -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tab.active a:after,
a:hover:before,
a:hover:after,
a:focus:before,
a:focus:after {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

a:hover:before,
a:focus:before {
  transition: opacity 0.2s,
    -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

a:hover:after,
a:focus:after {
  transition: opacity 0s 0.2s,
    -webkit-transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0s 0.2s;
  transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0s 0.2s,
    -webkit-transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

</style>

<style>
  .nav_root.h-100px .nav_desktop .top-cont {
    top: -34px;
  }
</style>