export default function registerGlobalSvgIcons(app) {
  app.component("svg-edit-pencil", {
    template: `
      <svg viewBox="0 0 19.871 19.871" style="enable-background:new 0 0 19.871 19.871;" xml:space="preserve">
      <path fill="currentColor" class="st0" d="M19.082,2.123L17.749,0.79c-1.052-1.052-2.766-1.054-3.819,0L1.925,12.794c-0.06,0.06-0.104,0.135-0.127,0.216
        l-1.778,6.224c-0.05,0.175-0.001,0.363,0.127,0.491c0.095,0.095,0.223,0.146,0.354,0.146c0.046,0,0.092-0.006,0.137-0.02
        l6.224-1.778c0.082-0.023,0.156-0.066,0.216-0.127L19.082,5.942C20.134,4.89,20.134,3.176,19.082,2.123z M3.076,13.057l9.428-9.428
        l3.738,3.739l-9.428,9.428L3.076,13.057z M2.566,13.961l3.345,3.344l-4.683,1.339L2.566,13.961z M18.375,5.235L16.95,6.66
        l-3.738-3.739l1.425-1.425c0.664-0.663,1.741-0.664,2.405,0l1.333,1.333C19.038,3.493,19.038,4.572,18.375,5.235z"/>
      </svg>
    `
  });

  app.component("svg-close-x", {
    template: `
    <svg fill="currentColor" viewBox="0 0 333.3 333.3" style="enable-background:new 0 0 333.3 333.3;" xml:space="preserve">
    <path d="M203.2,166.7L325.8,44.1c10.1-10.1,10.1-26.4,0-36.5c-10.1-10.1-26.4-10.1-36.5,0L166.7,130.2L44.1,7.6
      C34-2.5,17.6-2.5,7.6,7.6C-2.5,17.6-2.5,34,7.6,44.1l122.6,122.6L7.6,289.3c-10.1,10.1-10.1,26.4,0,36.5c5,5,11.6,7.6,18.3,7.6
      s13.2-2.5,18.3-7.6l122.6-122.6l122.6,122.6c5,5,11.7,7.6,18.3,7.6s13.2-2.5,18.3-7.6c10.1-10.1,10.1-26.4,0-36.5L203.2,166.7z"/>
    </svg>
  `
  });

  app.component("svg-close-x-blue-outline", {
    template: `
    <svg fill="currentColor" viewBox="0 0 333.3 333.3" style="enable-background:new 0 0 333.3 333.3;" xml:space="preserve">
    <path stroke="rgb(4, 43, 93)" stroke-width="20" d="M203.2,166.7L325.8,44.1c10.1-10.1,10.1-26.4,0-36.5c-10.1-10.1-26.4-10.1-36.5,0L166.7,130.2L44.1,7.6
      C34-2.5,17.6-2.5,7.6,7.6C-2.5,17.6-2.5,34,7.6,44.1l122.6,122.6L7.6,289.3c-10.1,10.1-10.1,26.4,0,36.5c5,5,11.6,7.6,18.3,7.6
      s13.2-2.5,18.3-7.6l122.6-122.6l122.6,122.6c5,5,11.7,7.6,18.3,7.6s13.2-2.5,18.3-7.6c10.1-10.1,10.1-26.4,0-36.5L203.2,166.7z"/>
    </svg>
  `
  });

  app.component("svg-check-mark", {
    template: `
    <svg fill="currentColor" viewBox="0 0 8.4 7.6" style="enable-background:new 0 0 8.4 7.6;" xml:space="preserve">
      <g>
        <path d="M3.1,7.6L0.2,4.8C0,4.6-0.1,4.3,0.1,4.1c0.2-0.2,0.5-0.2,0.7,0l2.1,2l4.6-5.9C7.7,0,8-0.1,8.2,0.1
          c0.2,0.2,0.3,0.5,0.1,0.7L3.1,7.6z"/>
      </g>   
    </svg>
  `
  });

  app.component("svg-family-tree", {
    template: `
    <svg fill="currentColor" viewBox="0 0 27.5 20.284" style="enable-background:new 0 0 27.5 20.284;" xml:space="preserve">
      <path d="M26.386,0h-2.412c-0.614,0-1.114,0.5-1.114,1.114v2.52c0,0.614,0.5,1.114,1.114,1.114h0.949v1.008h-7.432V4.749h1.033
        c0.614,0,1.114-0.5,1.114-1.114v-2.52C19.638,0.5,19.138,0,18.523,0h-2.412c-0.614,0-1.114,0.5-1.114,1.114v2.52
        c0,0.614,0.5,1.114,1.114,1.114h0.866v1.083c0,0.242,0.197,0.439,0.439,0.439h1.656v1.234h-0.955c-0.614,0-1.114,0.5-1.114,1.114
        v2.52c0,0.614,0.5,1.114,1.114,1.114h1.057v1.008h-5.417h-0.271H8.326v-1.008h1.058c0.614,0,1.114-0.5,1.114-1.114v-2.52
        c0-0.614-0.5-1.114-1.114-1.114H8.428V6.27h1.656c0.242,0,0.439-0.197,0.439-0.439V4.749h0.866c0.614,0,1.114-0.5,1.114-1.114v-2.52
        C12.503,0.5,12.003,0,11.389,0H8.977C8.363,0,7.863,0.5,7.863,1.114v2.52c0,0.614,0.5,1.114,1.114,1.114h1.032v1.008H2.577V4.749
        h0.949c0.614,0,1.114-0.5,1.114-1.114v-2.52C4.641,0.5,4.141,0,3.526,0H1.114C0.5,0,0,0.5,0,1.114v2.52
        c0,0.614,0.5,1.114,1.114,1.114h0.949v1.083c0,0.242,0.197,0.439,0.439,0.439h5.413v1.234H6.863c-0.614,0-1.114,0.5-1.114,1.114
        v2.52c0,0.614,0.5,1.114,1.114,1.114h0.949v1.083c0,0.242,0.197,0.439,0.439,0.439h5.235v1.313h-3.94
        c-0.614,0-1.114,0.5-1.114,1.114v2.968c0,0.614,0.5,1.114,1.114,1.114h8.393c0.614,0,1.114-0.5,1.114-1.114v-2.968
        c0-0.614-0.5-1.114-1.114-1.114H14v-1.313h5.249c0.242,0,0.439-0.197,0.439-0.439v-1.083h0.949c0.614,0,1.114-0.5,1.114-1.114v-2.52
        c0-0.614-0.5-1.114-1.114-1.114h-1.052V6.27h5.412c0.242,0,0.439-0.197,0.439-0.439V4.749h0.949c0.614,0,1.114-0.5,1.114-1.114
        v-2.52C27.5,0.5,27,0,26.386,0z M15.51,3.634v-2.52c0-0.331,0.27-0.601,0.601-0.601h2.412c0.331,0,0.601,0.27,0.601,0.601v2.52
        c0,0.331-0.27,0.601-0.601,0.601h-2.412C15.78,4.235,15.51,3.966,15.51,3.634z M8.376,3.634v-2.52c0-0.331,0.27-0.601,0.601-0.601
        h2.412c0.331,0,0.601,0.27,0.601,0.601v2.52c0,0.331-0.27,0.601-0.601,0.601H8.977C8.646,4.235,8.376,3.966,8.376,3.634z
         M0.513,3.634v-2.52c0-0.331,0.27-0.601,0.601-0.601h2.412c0.331,0,0.601,0.27,0.601,0.601v2.52c0,0.331-0.27,0.601-0.601,0.601
        H1.114C0.783,4.235,0.513,3.966,0.513,3.634z M6.262,11.139v-2.52c0-0.331,0.27-0.601,0.601-0.601h2.52
        c0.331,0,0.601,0.27,0.601,0.601v2.52c0,0.331-0.27,0.601-0.601,0.601h-2.52C6.532,11.74,6.262,11.47,6.262,11.139z M18.541,16.201
        v2.968c0,0.331-0.27,0.601-0.601,0.601H9.547c-0.331,0-0.601-0.27-0.601-0.601v-2.968c0-0.331,0.27-0.601,0.601-0.601h8.393
        C18.271,15.601,18.541,15.87,18.541,16.201z M21.238,8.618v2.52c0,0.331-0.27,0.601-0.601,0.601h-2.52
        c-0.331,0-0.601-0.27-0.601-0.601v-2.52c0-0.331,0.27-0.601,0.601-0.601h2.52C20.968,8.018,21.238,8.287,21.238,8.618z
         M26.987,3.634c0,0.331-0.27,0.601-0.601,0.601h-2.412c-0.331,0-0.601-0.27-0.601-0.601v-2.52c0-0.331,0.27-0.601,0.601-0.601h2.412
        c0.331,0,0.601,0.27,0.601,0.601V3.634z"/>
    </svg>
  `
  });

  app.component("svg-profile-icon", {
    template: `
    <svg fill="currentColor" viewBox="0 0 20.718 22.234" style="enable-background:new 0 0 20.718 22.234;" xml:space="preserve">
    <path
      style="fill:none;stroke:currentColor;stroke-width:0.5px;stroke-linecap:round;stroke-miterlimit:10;"
      class="st0" d="M20.468,21.984c-0.546-2.644-1.122-3.068-1.122-3.068s-0.848-0.696-3.956-1.773
      c-0.643-0.223-1.247-0.568-1.782-1.026c-0.117-0.099-0.231-0.202-0.341-0.304c-0.092-0.085-0.147-0.209-0.156-0.342
      c-0.014-0.226-0.034-0.607-0.045-0.99c-0.017-0.55-0.013-1.105,0.058-1.22c0,0,0.12-0.163,0.27-0.418
      c0.19-0.321,0.314-0.686,0.368-1.067c0.04-0.277,0.075-0.624,0.075-0.95c0,0,0.321,0.136,0.504-0.206c0,0,0.045-0.233,0.099-0.386
      c0.045-0.126,0.075-0.257,0.086-0.392c0.023-0.25,0.068-0.619,0.157-0.845c0.105-0.267,0.136-0.567,0.081-0.854
      c-0.055-0.278-0.178-0.509-0.454-0.369c0,0,0.013-0.082,0.05-0.216c0.134-0.499,0.212-1.014,0.221-1.536
      c0.006-0.324,0.025-0.673,0.081-0.94l-0.011,0.003c0.045-0.389,0.059-1.033-0.085-2.027c-0.194-1.324-0.945-1.405-0.945-1.405
      c-0.092-0.155-0.824-0.243-0.824-0.243l-0.822-0.787c0,0-0.137-0.291-0.137-0.223c0,0.07-0.335,0.155-0.335,0.155
      c0.075-0.223-0.718-0.085-0.718-0.085C9.472-0.116,9,0.657,9,0.657C7.901,0.708,8.115,1.017,8.115,1.017
      C7.307,1.291,6.743,2.285,6.743,2.285c-0.202,0-0.392,0.857-0.519,1.621c-0.1,0.598-0.126,1.206-0.096,1.813
      c0.008,0.153,0.011,0.307,0.011,0.45c0.001,0.438,0.064,0.872,0.19,1.286c0.059,0.192,0.079,0.318,0.079,0.318
      C6.132,7.634,6.008,7.865,5.954,8.144C5.899,8.429,5.93,8.729,6.034,8.996C6.124,9.222,6.17,9.593,6.192,9.843
      c0.011,0.134,0.041,0.263,0.085,0.388c0.054,0.153,0.1,0.389,0.1,0.389c0.184,0.342,0.504,0.206,0.504,0.206
      c0,0.327,0.035,0.673,0.075,0.95c0.054,0.381,0.178,0.746,0.369,1.067c0.149,0.254,0.269,0.418,0.269,0.418
      c0.072,0.115,0.075,0.671,0.058,1.22c-0.011,0.384-0.031,0.764-0.045,0.99c-0.007,0.134-0.064,0.257-0.156,0.342
      c-0.109,0.102-0.224,0.205-0.34,0.304c-0.535,0.457-1.141,0.803-1.783,1.026c-3.108,1.077-3.956,1.773-3.956,1.773
      s-0.576,0.425-1.122,3.068"/>
    </svg>
    
  `
  });

  app.component("svg-reports-icon", {
    template: `
    <svg fill="currentColor" viewBox="0 0 19.221 24.895" style="enable-background:new 0 0 19.221 24.895;" xml:space="preserve">
    <path fill="currentColor" d="M17.837,24.895H1.385C0.621,24.895,0,24.274,0,23.511V1.384C0,0.621,0.621,0,1.385,0h13.152l4.684,4.685v18.826
      C19.221,24.274,18.6,24.895,17.837,24.895z M1.385,0.5C0.896,0.5,0.5,0.896,0.5,1.384v22.127c0,0.487,0.396,0.884,0.885,0.884
      h16.452c0.487,0,0.884-0.396,0.884-0.884V4.892L14.33,0.5H1.385z M16.385,22.058h-3.337V14.75h3.337V22.058z M13.548,21.558h2.337
      V15.25h-2.337V21.558z M11.279,22.058H7.941v-5.039h3.338V22.058z M8.441,21.558h2.338v-4.039H8.441V21.558z M6.173,22.058H2.836
      v-8.444h3.337V22.058z M3.336,21.558h2.337v-7.444H3.336V21.558z M9.009,11.029h-0.5V9.895c0-1.013-1.713-1.451-2.586-1.451
      c-0.874,0-2.587,0.438-2.587,1.451v1.134h-0.5V9.895c0-1.015,1.024-1.562,1.974-1.801C4.304,7.741,3.972,7.153,3.972,6.49V5.923
      c0-1.076,0.875-1.951,1.951-1.951s1.952,0.875,1.952,1.951V6.49c0,0.664-0.332,1.25-0.839,1.604C7.985,8.333,9.009,8.88,9.009,9.895
      V11.029z M5.923,4.472c-0.8,0-1.451,0.651-1.451,1.451V6.49c0,0.801,0.651,1.453,1.451,1.453c0.801,0,1.452-0.652,1.452-1.453V5.923
      C7.375,5.123,6.724,4.472,5.923,4.472z M16.702,10.712h-6.24v-0.5h6.24V10.712z M16.702,8.443h-6.24v-0.5h6.24V8.443z M17.27,5.606
      h-3.654V1.951h0.5v3.155h3.154V5.606z"/>
    </svg>
  `
  });

  app.component("svg-locksmith-icon", {
    template: `
    <svg fill="currentColor" viewBox="0 0 24.28 27.28" style="enable-background:new 0 0 24.28 27.28;" xml:space="preserve">
    <path style="fill:currentColor;" d="M24.28,12.46c0,3.33-1.26,6.46-3.56,8.81l-0.45-0.46c2.17-2.23,3.37-5.2,3.37-8.35c0-6.51-5.16-11.81-11.51-11.81
      S0.63,5.95,0.63,12.46c0,6.28,4.8,11.43,10.83,11.79l-1.86-2.39l0.49-0.41l2.29,2.94c0.08,0.1,0.09,0.23,0.04,0.34
      c-0.02,0.04-0.05,0.06-0.08,0.09l0.01,0.01l-2.62,2.45L9.31,26.8l2.06-1.93C5.03,24.46,0,19.06,0,12.46C0,5.59,5.45,0,12.14,0
      S24.28,5.59,24.28,12.46z M14.78,9.39h3.13v8.99H6.37V9.39h7.79V7.49c0-1.63-0.9-2.96-2.01-2.96c-1.11,0-2.01,1.33-2.01,2.96H9.5
      c0-1.99,1.18-3.61,2.64-3.61c1.46,0,2.64,1.62,2.64,3.61V9.39z M17.29,10.04H7v7.69h10.29V10.04z M10.41,12.98
      c0-0.98,0.78-1.78,1.73-1.78s1.73,0.8,1.73,1.78c0,0.87-0.61,1.59-1.42,1.74v1.53h-0.63v-1.53C11.02,14.57,10.41,13.85,10.41,12.98z
       M11.04,12.98c0,0.62,0.49,1.13,1.1,1.13s1.1-0.51,1.1-1.13c0-0.62-0.49-1.13-1.1-1.13S11.04,12.36,11.04,12.98z"/>
    </svg>
  `
  });

  app.component("svg-my-locksmith-icon", {
    template: `
    <svg fill="currentColor" viewBox="0 0 20.00008 19.99951" style="enable-background:new 0 0 20.00008 19.99951;" xml:space="preserve">
      <g>
        <path d="M6.85506,0C4.52303,0,2.63876,1.42188,2.3746,3.38086C2.29159,3.99463,2.26717,4.50098,2.37362,5.41992
          c0.08887,0.76367,0.28662,2.00146,0.4624,2.74707c0.02148,0.09521,0.42822,1.86475,1.56836,2.80078
          c0.00098,0.0083,0.00244,0.0166,0.00391,0.0249c0.14746,0.73828,0.17969,1.53027,0.06836,1.66113
          c-0.41699,0.41699-2.83887,0.95117-4.26758,1.18848c-0.13623,0.02344-0.22852,0.15234-0.20557,0.28809
          c0.02051,0.12207,0.12646,0.20898,0.24609,0.20898c0.01367,0,0.02783-0.00098,0.0415-0.00293
          c0.39453-0.06641,3.87305-0.66211,4.53857-1.3291c0.2876-0.28613,0.23193-1.09375,0.14453-1.68018
          c0.18018,0.08203,0.37354,0.14355,0.58203,0.17822c0.13525,0.02344,0.26465-0.06934,0.2876-0.20557
          s-0.06934-0.26514-0.20557-0.2876C3.94003,10.729,3.33016,8.08496,3.32333,8.05518C3.15096,7.32422,2.9576,6.11182,2.87069,5.3623
          C2.7701,4.49609,2.79256,4.021,2.86971,3.44727C3.10018,1.73975,4.77645,0.5,6.85506,0.5
          c2.07959,0,3.75537,1.23975,3.98438,2.94727c0.07715,0.5752,0.1001,1.05078-0.00098,1.91504
          c-0.0874,0.75146-0.28027,1.96436-0.45312,2.69629c-0.00586,0.02637-0.61621,2.67041-2.31299,2.95361
          c-0.13623,0.02295-0.22852,0.15137-0.20557,0.2876s0.15234,0.229,0.2876,0.20557c0.20801-0.03467,0.40137-0.09619,0.58154-0.17822
          c-0.0874,0.58643-0.14307,1.39404,0.14355,1.68018c0.0957,0.0957,0.38623,0.38574,2.00244,0.79688
          c0.13379,0.03223,0.27051-0.04688,0.30371-0.18066c0.03418-0.13379-0.04688-0.26953-0.18066-0.30371
          c-1.33838-0.34082-1.68506-0.5791-1.771-0.66504c-0.11182-0.13184-0.07959-0.92383,0.06738-1.66211
          c0.00146-0.0083,0.00293-0.01611,0.00391-0.02441c1.14014-0.93604,1.54688-2.70508,1.56738-2.79785
          c0.17627-0.74658,0.37354-1.98486,0.4624-2.75049c0.10742-0.9165,0.08301-1.42383,0-2.03906C11.07235,1.42188,9.18807,0,6.85506,0z
          "/>
        <path d="M19.12118,12.26709h-0.37109v-1.6333c0-1.55518-1.26172-2.8208-2.81152-2.8208c-1.55176,0-2.81348,1.26562-2.81348,2.8208
          v1.6333h-0.29492c-0.48438,0-0.87891,0.39453-0.87891,0.87891v5.97461c0,0.48438,0.39453,0.87891,0.87891,0.87891h6.29102
          c0.48438,0,0.87891-0.39453,0.87891-0.87891V13.146C20.00008,12.66162,19.60555,12.26709,19.12118,12.26709z M13.62508,10.63379
          c0-1.27979,1.03809-2.3208,2.31348-2.3208c1.27441,0,2.31152,1.04102,2.31152,2.3208v1.6333h-4.625V10.63379z M19.50008,19.12061
          c0,0.20898-0.16992,0.37891-0.37891,0.37891h-6.29102c-0.20898,0-0.37891-0.16992-0.37891-0.37891V13.146
          c0-0.20898,0.16992-0.37891,0.37891-0.37891h0.29492v0.0459h0.5v-0.0459h4.625v0.0459h0.5v-0.0459h0.37109
          c0.20898,0,0.37891,0.16992,0.37891,0.37891V19.12061z"/>
        <path d="M15.93758,14.06299c-0.68945,0-1.25,0.54785-1.25,1.22168c0,0.30469,0.12012,0.59961,0.33301,0.8252v1.11816
          c0,0.49512,0.41113,0.89844,0.91699,0.89844s0.91699-0.40332,0.91699-0.89844v-1.11816
          c0.21289-0.22559,0.33301-0.52051,0.33301-0.8252C17.18758,14.61084,16.62704,14.06299,15.93758,14.06299z M16.43954,15.81689
          c-0.05371,0.04688-0.08496,0.11523-0.08496,0.1875v1.22363c0,0.21973-0.1875,0.39844-0.41699,0.39844
          s-0.41699-0.17871-0.41699-0.39844v-1.22363c0-0.07227-0.03125-0.14062-0.08496-0.1875
          c-0.16016-0.14062-0.24805-0.33008-0.24805-0.53223c0-0.39746,0.33691-0.72168,0.75-0.72168s0.75,0.32422,0.75,0.72168
          C16.68758,15.48682,16.59969,15.67627,16.43954,15.81689z"/>
      </g>
    </svg>
  `
  });

  app.component("svg-arrow-fat", {
    template: `
    <svg fill="currentColor" viewBox="0 0 1099.2 358.1" style="enable-background:new 0 0 1099.2 358.1;" xml:space="preserve">
      <path d="M1094.4,21.1c-10.4-19.4-34.7-26.7-54.1-16.3L549.6,267.4L59,4.8c-9.4-5.1-20.2-6.2-30.5-3.1
        C18.3,4.8,9.8,11.7,4.8,21.1c-5.1,9.4-6.2,20.2-3.1,30.5c3.1,10.2,10,18.7,19.4,23.7l528.5,282.9l528.5-282.9
        C1097.5,64.8,1104.8,40.6,1094.4,21.1z"/>
    </svg>
    
  `
  });

  app.component("svg-arrow-normal", {
    template: `
    <svg fill="currentColor"  viewBox="0 0 1069.1 326.1" style="enable-background:new 0 0 1069.1 326.1;" xml:space="preserve">
      <g>
        <path d="M534.6,326.1L13.2,47C1,40.5-3.6,25.4,3,13.2C9.5,1,24.6-3.6,36.8,3l497.8,266.4L1032.4,3c12.2-6.5,27.3-1.9,33.8,10.2
          c6.5,12.2,1.9,27.3-10.2,33.8L534.6,326.1z"/>
      </g>
      <path d="M322,176"/>
      <path d="M-33.6,176"/>
    </svg>
    
  `
  });

  app.component("svg-plus-fat", {
    template: `
    <svg class="w-100 h-100" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100" xml:space="preserve">
      <g>
        <line
          x1="10" x2="90" y1="50" y2="50"
          stroke="currentColor" stroke-width="20" stroke-linecap="round"
        ></line>
        <line
          x1="50" x2="50" y1="10" y2="90"
          stroke="currentColor" stroke-width="20" stroke-linecap="round"
        ></line>
      </g>
    </svg>
  
  `
  });

  app.component("svg-minus-fat", {
    template: `
     <svg class="w-100 h-100" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100" xml:space="preserve">
        <g>
          <line
            x1="10" x2="90" y1="50" y2="50"
            stroke="currentColor" stroke-width="20" stroke-linecap="round"
          ></line>
        </g>
     </svg>
  `
  });

  app.component("svg-closed-lock", {
    template: `
    <svg fill="currentColor" viewBox="0 0 27.749 45.112" style="enable-background:new 0 0 27.749 45.112;" xml:space="preserve">

      <path  d="M13.873,45.112c-4.289,0-8.557-0.556-12.345-1.605C0.628,43.255,0,42.408,0,41.443V23.606
        c0-0.967,0.628-1.815,1.528-2.065c0.457-0.125,0.934-0.233,1.411-0.341l0.159-0.035v-9.85C3.098,5.076,7.931,0,13.873,0
        c5.941,0,10.776,5.076,10.776,11.315v9.849l0.14,0.032c0.484,0.109,0.967,0.218,1.43,0.345c0.901,0.25,1.531,1.099,1.531,2.065
        v17.837c0,0.964-0.628,1.812-1.529,2.064C22.377,44.572,18.222,45.112,13.873,45.112z M13.873,0.63
        c-5.594,0-10.146,4.794-10.146,10.685v10.353l-0.651,0.148c-0.468,0.105-0.936,0.21-1.382,0.332
        c-0.626,0.175-1.065,0.775-1.065,1.458v17.837c0,0.683,0.439,1.283,1.067,1.457c3.733,1.034,7.944,1.58,12.176,1.58
        c4.293,0,8.389-0.531,12.179-1.58c0.628-0.174,1.065-0.774,1.065-1.457V23.606c0-0.683-0.437-1.283-1.065-1.458
        c-0.454-0.124-0.928-0.23-1.402-0.337l-0.634-0.144V11.315C24.017,5.424,19.467,0.63,13.873,0.63z M13.873,38.816
        c-1.292,0-2.342-1.098-2.342-2.448v-2.614c-0.859-0.735-1.347-1.812-1.347-2.978c0-2.132,1.655-3.866,3.69-3.866
        c2.036,0,3.692,1.734,3.692,3.866c0,1.164-0.49,2.241-1.347,2.978v2.614C16.218,37.718,15.166,38.816,13.873,38.816z M13.873,27.542
        c-1.687,0-3.06,1.451-3.06,3.234c0,1.024,0.447,1.966,1.228,2.582l0.119,0.096v2.914c0,1.001,0.769,1.816,1.712,1.816
        c0.945,0,1.715-0.815,1.715-1.816v-2.914l0.119-0.094c0.78-0.618,1.226-1.56,1.226-2.584C16.933,28.993,15.561,27.542,13.873,27.542
        z M20.36,21l-0.355-0.044c-4.08-0.515-8.184-0.515-12.263,0L7.387,21v-9.685c0-3.753,2.91-6.806,6.487-6.806
        s6.487,3.052,6.487,6.806V21z M13.873,5.139c-3.229,0-5.857,2.77-5.857,6.176v8.972c3.901-0.466,7.816-0.466,11.711,0v-8.972
        C19.728,7.91,17.101,5.139,13.873,5.139z"/>
    </svg>
    
  `
  });

  app.component("svg-checkmark-fat", {
    template: `
    <svg fill="currentColor" viewBox="0 0 10.371 9.55" style="enable-background:new 0 0 10.371 9.55;" xml:space="preserve">
      <path d="M10.371,0.711c0-0.256-0.03-0.439-0.091-0.548C10.219,0.055,10.117,0,9.973,0C9.782,0,9.55,0.09,9.276,0.268
        C9.002,0.446,8.691,0.709,8.344,1.056l-4.14,4.101C3.957,5.4,3.744,5.592,3.566,5.734c-0.179,0.141-0.3,0.211-0.365,0.211
        c-0.157,0-0.281-0.119-0.372-0.358C2.738,5.348,2.657,4.926,2.588,4.322C2.584,4.279,2.577,4.211,2.568,4.12
        C2.486,3.334,2.229,2.94,1.799,2.94c-0.452,0-0.865,0.19-1.238,0.571C0.187,3.891,0,4.305,0,4.752C0,5.574,0.042,6.336,0.127,7.04
        s0.175,1.165,0.271,1.382c0.113,0.261,0.362,0.514,0.749,0.76C1.534,9.427,1.858,9.55,2.119,9.55c0.247,0,0.672-0.245,1.274-0.736
        C3.995,8.322,4.724,7.627,5.58,6.728l4.028-4.244L9.68,2.412c0.461-0.482,0.691-0.977,0.691-1.486V0.711z"/>
      </svg>
    
  `
  });

  app.component("svg-refine-results", {
    template: `
    <svg fill="currentColor" viewBox="0 0 21.3779297 20.2216797" style="enable-background:new 0 0 21.3779297 20.2216797;" xml:space="preserve">
      <path d="M6.5223999,15.883667c0.5499268-0.3956299,0.9053345-1.0650024,0.9053345-1.8397217v-0.6640625
      \tc0-1.2451172-0.9165039-2.2197266-2.0864258-2.2197266s-2.0864258,0.9746094-2.0864258,2.2197266v0.6640625
      \tc0,0.8275146,0.4096069,1.5279541,1.0245972,1.90979c-1.1715698,0.2695923-2.4196167,0.8859863-2.4196167,1.9241943v2.34375
      \th0.453125v-2.34375c0-0.9940186,1.8153076-1.5427856,2.987854-1.6184692
      \tc0.013855,0.0002441,0.0265503,0.0042114,0.0404663,0.0042114c0.0465698,0,0.0897827-0.0110474,0.135498-0.0140991
      \tc0.0306396-0.0007324,0.0670166-0.0054321,0.0964355-0.0054321c1.1381836,0,3.2612305,0.5615234,3.2612305,1.6337891v2.34375
      \th0.453125v-2.34375C9.2875977,16.7365723,7.779541,16.1047363,6.5223999,15.883667z M3.7080078,14.0439453v-0.6640625
      \tc0-0.9902344,0.7177734-1.7666016,1.6333008-1.7666016s1.6333008,0.7763672,1.6333008,1.7666016v0.6640625
      \tc0,0.9287109-0.6339722,1.6605835-1.4677124,1.7490845c-0.0755615,0.0012207-0.1542358,0.0043335-0.2368774,0.0099487
      \tC4.3895874,15.7629395,3.7080078,15.0076904,3.7080078,14.0439453z M21.3779297,1.621582v9.300293
      \tc0,0.8935547-0.7275391,1.6210938-1.6210938,1.6210938h-6.8759766l-2.3549805,2.5771484
      \tC10.3994141,15.2832031,10.2045898,15.375,9.9975586,15.375c-0.0761719,0-0.1542969-0.0126953-0.230957-0.0390625
      \tc-0.2836914-0.0976562-0.4667969-0.3544922-0.4667969-0.6542969v-2.1386719h-1.168457v-0.453125h1.621582v2.5917969
      \tc0,0.1582031,0.1230469,0.2128906,0.1606445,0.2255859c0.0371094,0.0117188,0.1679688,0.046875,0.265625-0.0800781
      \tl2.5024414-2.7373047h7.0751953c0.6435547,0,1.1679688-0.5244141,1.1679688-1.1679688V1.621582
      \tc0-0.644043-0.5244141-1.168457-1.1679688-1.168457H1.621582c-0.644043,0-1.168457,0.5244141-1.168457,1.168457v9.300293
      \tc0,0.6435547,0.5244141,1.1679688,1.168457,1.1679688h0.9296875v0.453125H1.621582C0.7275391,12.5429688,0,11.8154297,0,10.921875
      \tV1.621582C0,0.7275391,0.7275391,0,1.621582,0h18.1352539C20.6503906,0,21.3779297,0.7275391,21.3779297,1.621582z
      \t M18.1230469,3.2553711h-6.0332031v6.0327148h6.0332031V3.2553711z M17.6699219,8.8349609h-5.1269531V3.7084961h5.1269531V8.8349609
      \tz M14.6416016,7.8881836l-1.1191406-1.6777344l0.3769531-0.2519531l0.7421875,1.1123047L16.3125,4.5634766l0.3769531,0.2519531
      \tL14.6416016,7.8881836z M9.2880859,3.2553711H3.2548828v6.0327148h6.0332031V3.2553711z M8.8349609,8.8349609H3.7080078V3.7084961
      \th5.1269531V8.8349609z M4.7163086,7.5068359l1.2348633-1.2348633L4.7163086,5.0371094l0.3203125-0.3203125l1.2348633,1.2348633
      \tl1.2348633-1.2348633l0.3203125,0.3203125L6.5917969,6.2719727l1.2348633,1.2348633L7.5063477,7.8271484L6.2714844,6.5922852
      \tL5.0366211,7.8271484L4.7163086,7.5068359z"/>
    </svg>
  `
  });

  app.component("svg-question-mark-circle", {
    template: (`
    <svg fill="currentColor"  viewBox="0 0 87.5981674 87.5981674" style="enable-background:new 0 0 87.5981674 87.5981674;" xml:space="preserve">
      <g>
      \t<path d="M43.8007622,60.7954712c-2.1184731,0-3.8447609,1.7296371-3.8447609,3.8481064
      \t\tc0,2.1184769,1.7262878,3.848114,3.8447609,3.848114c2.1218224,0,3.8481102-1.7296371,3.8481102-3.848114
      \t\tC47.6488724,62.5251083,45.9225845,60.7954712,43.8007622,60.7954712z"/>
      \t<path d="M74.7733688,12.8281555C66.5005951,4.5587382,55.4992867,0,43.8007622,0S21.1009254,4.5587382,12.8281555,12.8281555
      \t\tC4.5587382,21.1009254,0,32.1022339,0,43.8007622s4.5587382,22.6998329,12.8281555,30.9726067
      \t\tc8.2727699,8.2660599,19.2707253,12.8247986,30.9726067,12.8247986c11.7052307,0,22.6998329-4.5587387,30.9726067-12.8247986
      \t\tc8.2660599-8.2727737,12.8247986-19.2673759,12.8247986-30.9726067
      \t\tC87.5981674,32.0988808,83.0394287,21.1009254,74.7733688,12.8281555z M43.8007622,82.3824387
      \t\tc-10.3074417,0-19.9947624-4.0157166-27.2753353-11.3029938c-7.2906294-7.2872734-11.30299-16.9745941-11.30299-27.2786827
      \t\tc0-10.3107948,4.0123606-19.9981136,11.30299-27.2820396C23.8059998,9.2347975,33.4899673,5.2224369,43.8007622,5.2224369
      \t\tc10.3040886,0,19.9914093,4.0123606,27.2786827,11.2996387c7.2872772,7.2839241,11.3029938,16.9712448,11.3029938,27.2786865
      \t\tc0,10.3040886-4.0157166,19.9914093-11.3029938,27.2786827S54.1048508,82.3824387,43.8007622,82.3824387z"/>
      \t<path d="M45.8287277,17.8058968h-0.0033493c-3.5296707-0.1642494-6.9755402,0.8279457-9.6370392,2.9028435
      \t\tc-2.9799423,2.3262978-4.6827641,5.6447906-4.9274616,9.593462c-0.0435753,0.6938667,0.1843624,1.3642693,0.6435871,1.8871822
      \t\tc0.4625778,0.5229149,1.0961075,0.8346519,1.8000298,0.8815804c1.5788002,0.1340828,2.6782608-1.0156593,2.7620621-2.4436169
      \t\tc0.2514-4.0358238,3.4358139-7.9543285,9.0571404-7.6157742c3.6402855,0.214529,6.8381081,2.5542336,7.7900772,5.6950703
      \t\tc0.543026,1.7933273,0.3150902,3.8883343-0.6435852,5.7554073c-0.9251556,1.8302002-2.4938965,3.398941-4.1632004,5.0648918
      \t\tc-0.4994507,0.4927483-1.0089569,1.0022545-1.5084076,1.5251656c-1.7396927,1.8268471-3.0670929,3.5833054-4.059288,5.3699265
      \t\tc-0.5698433,1.0357742-0.8782272,2.0614891-1.2435951,3.28162c-0.5195618,1.6994743-0.5597878,4.6291313-0.5597878,5.4872475
      \t\tc0,1.4346619,1.1698532,2.6078682,2.6078682,2.6078682c1.4380112,0,2.6112175-1.1732063,2.6112175-2.6078682
      \t\tc0-1.7966805,0.1675987-3.4358139,0.4223518-4.2704659c0.2145309-0.7240334,0.4156494-1.4078445,0.7307396-1.9743347
      \t\tc0.7575569-1.3743248,1.8302002-2.77882,3.2849731-4.3039856c0.4424667-0.4759865,0.9251556-0.9553223,1.3944397-1.4179001
      \t\tc1.7832718-1.7832718,3.807888-3.8045387,5.1419868-6.392292c1.555336-3.0570374,1.9106483-6.5732975,0.9787903-9.6437416
      \t\tC56.7395325,22.0059681,51.604248,18.1478004,45.8287277,17.8058968z"/>
      </g>
    </svg>
`)});

  app.component("svg-full-arrow", {
    template: `
    <svg fill="currentColor" viewBox="0 0 13.2001953 11.0004482" style="enable-background:new 0 0 13.2001953 11.0004482;" xml:space="preserve">
      <path class="st0" d="M6.8863769,0.4361051" style="fill:none;stroke: currentColor;stroke-width:1.1570439;stroke-linecap:round;"/>
      <path class="st1" style="fill: currentColor" d="M0.5,5.0004478h10.6465454L6.5517578,0.8725181c-0.2055664-0.1845703-0.222168-0.5009766-0.0375977-0.706543
      \tc0.1850586-0.2060547,0.5009766-0.222168,0.706543-0.0375977l5.9794922,5.3720703l-5.9794922,5.3720708
      \tC7.125,10.958456,7.0053711,11.0004482,6.8862305,11.0004482c-0.1367188,0-0.2734375-0.0556641-0.3720703-0.1660156
      \tc-0.1845703-0.2050781-0.1679688-0.5214844,0.0375977-0.7060547l4.5947876-4.1279302H0.5c-0.2763672,0-0.5-0.2236328-0.5-0.5
      \tC0,5.2240806,0.2236328,5.0004478,0.5,5.0004478z"/>
    </svg>
  `
  });

  app.component("svg-", {
    template: `
    <svg fill="currentColor" 
    
  `
  });
}
