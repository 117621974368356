import {nextTick} from 'vue'
import {createRouter, createWebHistory} from "vue-router";

import store_vuex from "@/store_vuex";
import { TokenService } from "@/services/storage.service";

import routes from "./routes";
import setupMetadata from "@/router/setupMetadata";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return handleToHashScroll(to)
    else return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)  // to scroll to top on route change
  const redirect_route_object = redirectIfNotAuthorized(to)
  if (redirect_route_object) return next(redirect_route_object)
  next();
});

router.first_load = true;
router.afterEach((to, from) => {
  if (store_vuex.state.core.initialized === -1) store_vuex.dispatch("core/initialAppSetup")
  store_vuex.dispatch("core/updateActiveTab", to.name);

  if (to.params.rerender) store_vuex.dispatch("core/rerender");

  nextTick(() => {
    if (router.first_load) {router.first_load = false;if (window.location.href.indexOf("localhost") === -1) return;}
    setupMetadata(to)
  });

});

export default router;


function handleToHashScroll(to) {
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        selector: to.hash,
        offset: { x: 0, y: 100 }
      });
    }, 500);
  });
}

function redirectIfNotAuthorized(to) {
  const matched = to.matched.length > 0,
    isPublic = to.matched.some(record => record.meta.public),
    onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut),
    loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn && matched) return {name: "login", params: to.params, query: { redirect: to.fullPath }}
  if (loggedIn && onlyWhenLoggedOut && matched) return { name: "home", params: to.params, }
}
