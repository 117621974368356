import { createStore, createLogger } from 'vuex'
import core from "@/store_vuex/modules/core"
import lang from "@/store_vuex/modules/lang"
import auth from "@/store_vuex/modules/auth"
import responsive from "@/store_vuex/modules/responsive"
import profile from "@/store_vuex/modules/profile"
import family_tree from "@/store_vuex/modules/family_tree"
import free_report from "@/store_vuex/modules/free_report"
import premium_report from "@/store_vuex/modules/premium_report"
import refine_results from "@/store_vuex/modules/refine_results"

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    core,
    lang,
    auth,
    responsive,
    profile,
    family_tree,
    free_report,
    premium_report,
    refine_results
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
