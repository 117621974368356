
export default function setupMetadata(to) {
  deleteAllSocMedMetaDataContent()
  setup()

  function setup() {
    const lang = to.params.lang;
    if (to.meta && to.meta.meta_tags && to.meta.meta_tags.hasOwnProperty(lang)) {
      document.title = to.meta.meta_tags[lang].title;
      getMeta("title").content = to.meta.meta_tags[lang].meta_title;
      getMeta("description").content = to.meta.meta_tags[lang].description;
      getMeta("keywords").content = to.meta.meta_tags[lang].keywords;
      getMeta("twitter:image").content = to.meta.meta_tags[lang].twitter_image || "";
      getMeta("og:image", true).content = to.meta.meta_tags[lang].og_image || "";
    } else {
      document.title = "Terra Adriatica";
      getMeta("title").content = "";
      getMeta("description").content = "";
      getMeta("keywords").content = "";
    }
  }

  function getMeta(name, is_prop) {
    return document.querySelector(`head meta[${is_prop ? 'property' : 'name'}='${name}']`);
  }

  function deleteAllSocMedMetaDataContent() {
    document.querySelectorAll("head meta").forEach(el => {
      if (
        (el.property && el.property.indexOf("og:") !== -1) ||
        (el.name && el.name.indexOf("twitter:") !== -1)
      )
        el.content = "";
    });
  }
}