<template>
  <Modal :show_modal="$store.state.core.modal_unavailable_open" :closeModal="()=>$store.dispatch('core/updateModalUnavailableOpen', false)">
    <div>
      <h4>{{ t.h1 }}</h4>
      <button class="btn button_slide_white btn_blue mt-4"
              @click="() => {$router.push({name: 'home', query: {update: '1'}}); $store.dispatch('core/updateModalUnavailableOpen', false)}"
      >{{ t.p1 }}</button>
    </div>
  </Modal>
</template>

<script>

const t =
//<i18n>
{
  en: {
    h1: 'Currently Unavailable',
    p1: 'Read more'
  },
  hr: {
    h1: 'Trenutno nedostupno',
    p1: 'Saznaj više'
  }
}
import Login from "@/components/Auth/Login";
import Modal from "@/components/Elements/Modal"
import Registration from "@/components/Auth/Registration"

export default {
  name: "UnavailableModal",
  components: {Registration, Modal, Login},
  data() {
    return {
      t: t[this.$lang]
    };
  },
};
</script>

<style scoped></style>
