export default
//<i18n>
  {
    "en": {
      login_title: "Login at Terra Adriatica",
      login_meta_title: "Terra Adriatica Login",
      login_description: "Login to your account and explore your claims to land in Terra Adriatica portal. Get updates, order property reports and register your interest in Locksmith.",
      login_keywords: "Login,Terra Adriatica,Terra Adriatica portal,property report,Locksmith,",

      registration_title: "Register at Terra Adriatica",
      registration_meta_title: "Terra Adriatica Register",
      registration_description: "Register by creating an account with Terra Adriatica. Gain access to exclusive features and explore what valid claim to land in Croatia you may have.",
      registration_keywords: "register,Terra Adriatica,valid claim to land,land in Croatia,exclusive features,",

      home_title:
        "Terra Adriatica - Solution to an Age-Old Croatian Land Title Issue",
      home_meta_title: "Terra Adriatica Home",
      home_description:
        "Terra Adriatica uses advanced technology to help solve the land ownership issue in Croatia. Find your valid claim to land in Croatia and unlock its value.",
      home_keywords:
        "land title Croatia,land ownership Croatia,land ownership issue,valid claim to land,unlocking land value,",

      locksmith_title:
        "Terra Adriatica – Locksmith",
      locksmith_meta_title: "Locksmith at Terra Adriatica",
      locksmith_description:
        "Identify, validate and unlock value from your ancestral right to land at zero upfront cost and help Croatia to return billions of square meters of economically dormant land to productivity.",
      locksmith_keywords:
        "Locksmith,terra adriatica,ancestral land,land croatia,identify land croatia,",

      reports_title:
        "Terra Adriatica Reports",
      reports_meta_title: "Terra Adriatica Reports",
      reports_description:
        "Download a copy of your free, fully personalized report on your family’s possible claim to Croatian land - at zero obligation to yourself.",
      reports_keywords:
        "Land report,terra adriatica,land croatia,personalized land report,find land in croatia,",

      technology_title:
        "Technology at Terra Adriatica – Advanced Technology Solving Complex Land Issue",
      technology_meta_title: "Terra Adriatica Technology",
      technology_description:
        "AI, Machine Learning, Natural Language Processing, Neural Networks, Process Automation - we use advanced technology to solve land ownership issues in Croatia.",
      technology_keywords:
        "land title croatia,land ownership croatia,terra adriatica,advanced technology,ai,machine learning,",

      blog_title:
        "Terra Adriatica Blog – Announcements, Insights, Features and Tips",
      blog_meta_title: "Terra Adriatica Blog",
      blog_description:
        "Read our blog posts and deepen your understanding of the land ownership issue in Croatia. Learn project’s fun facts, meet the Team and share the knowledge.",
      blog_keywords:
        "terra adriatica blog,ta blog,croatian land registry facts,land ownership facts,land in Croatia fun,",
      blog_twitter_image: "https://terraadriatica.hr/static/images/Terra Adriatica Blog FB thumbnail.jpg",
      blog_og_image: "https://terraadriatica.hr/static/images/Terra Adriatica Blog FB thumbnail.jpg",

      about_title:
        "About Terra Adriatica Project, Motivation and the Team Behind It All",
      about_meta_title: "Terra Adriatica About",
      about_description:
        "If time and cost was of no consequence, can it be done? Can we establish clean title and unlock land value? Learn about our Project and great minds behind it.",
      about_keywords:
        "land title croatia,land ownership croatia,terra adriatica,unlock land value,about terra adriatica,terra adriatica team,",

      faq_title:
        "FAQ at Terra Adriatica – Why, When and How to Use Our Services ",
      faq_meta_title: "Terra Adriatica FAQ",
      faq_description:
        "You ask, we answer. How is Terra Adriatica Project possible, viable and successful? Learn how we establish clean title and sell the land at the highest value.",
      faq_keywords:
        "land title croatia,land ownership croatia,terra adriatica,establish clean title,land value,terra Adriatica faq,",

      journey_zero_title: "Start Your Journey with Terra Adriatica",
      journey_zero_meta_title: "Start Your Journey with Terra Adriatica",
      journey_zero_description:
        "Explore your land DNA with Terra Adriatica. Start your journey by entering your Croatian surname and discover what valid claims to land in Croatia you may have.",
      journey_zero_keywords:
        "Start your journey,land DNA,land ownership,Croatian surname,valid claim to land,terra adriatica,",

      tos_title: "Terms of Service at Terra Adriatica",
      tos_meta_title: "Terra Adriatica ToS",
      tos_description:
        "Terms of Service govern the use of our websites, services and mobile apps. Read Terra Adriatica Terms and Conditions of Service and learn your rights.",
      tos_keywords:
        "land title croatia,land ownership croatia,terra adriatica,terra adriatica terms of service,terms and conditions,",

      privacy_policy_title: "Privacy Policy at Terra Adriatica",
      privacy_policy_meta_title: "Terra Adriatica Privacy Policy",
      privacy_policy_description:
        "We take your personal data security seriously. Working on resolving land ownership issue requires us to ask for your personal data, and here is how we use it.",
      privacy_policy_keywords:
        "land title croatia,land ownership croatia,terra adriatica,terra adriatica privacy policy,terra adriatica personal data,terra adriatica data security,",

      cookie_policy_title: "Cookie Policy at Terra Adriatica",
      cookie_policy_meta_title: "Terra Adriatica Cookie Policy",
      cookie_policy_description:
        "Terra Adriatica website uses cookies to help improve your user experience. We use additional analytical tools to analyse your behaviour on our website.",
      cookie_policy_keywords:
        "land title croatia,land ownership croatia,terra adriatica,terra adriatica cookie policy,terra adriatica cookies,terra adriatica analytics,",

      compliance_title: "Compliance at Terra Adriatica",
      compliance_meta_title: "Terra Adriatica Compliance",
      compliance_description: "",
      compliance_keywords: "",


    },
    "hr": {
      login_title: "Terra Adriatica Prijava",
      login_meta_title: "Terra Adriatica Prijava",
      login_description: "Prijavite se na svoj račun i istražite vlasnička prava u portalu Terra Adriatice. Saznajte novosti, naručite zemljišno izvješće i prijavite svoj interes za Locksmith.",
      login_keywords: "login,Terra Adriatica,Terra Adriatica portal,vlasnička prava,zemljišno izvješće,Locksmith,",

      registration_title: "Terra Adriatica - Registracija",
      registration_meta_title: "Terra Adriatica - Registracija",
      registration_description:
        "Registrirajte se izradom računa na Terra Adriatica portalu. Otključajte dodatni sadržaj i otkrijte svoja legitimna vlasnička prava na zemljište u Hrvatskoj.",
      registration_keywords:
        "registriraj,Terra Adriatica portal,legitimna vlasnička prava,zemljište u Hrvatskoj,dodatni sadržaj,",

      home_title:
        "Terra Adriatica – uspostava čistog zemljišnog vlasništva u Hrvatskoj",
      home_meta_title: "Terra Adriatica Početna",
      home_description:
        "Terra Adriatica koristi naprednu tehnologiju za rješavanje vlasničkog pitanja u Hrvatskoj. Otkrijte pravo na zemljište u Hrvatskoj i otključajte mu vrijednost.",
      home_keywords:
        "Pravo na zemljište,zemljište u Hrvatskoj,napredna tehnologija,vlasničko pitanje,Terra Adriatica,",

      locksmith_title:
        "Locksmith Terra Adriatice",
      locksmith_meta_title: "Terra Adriatica – Locksmith",
      locksmith_description:
        "Identificirajte, potvrdite i otključajte vrijednost prava na zemljište Vaših predaka u Hrvatskoj bez ikakvih troškova unaprijed.",
      locksmith_keywords:
        "Locksmith,terra adriatica,bez troškova,zemljište hrvatska,zemljište u hrvatskoj,vrijednost zemljišta,",

      reports_title:
        "Izvješća Terra Adriatice",
      reports_meta_title: "Terra Adriatica izvješća",
      reports_description:
        "Preuzmite primjerak svog besplatnog, potpuno personaliziranog izvješća o mogućem pravu na hrvatsko zemljište – bez ikakvih obveza.",
      reports_keywords:
        "izvješće,terra adriatica,zemljište u hrvatskoj,personalizirano izvješće,zemljišno izvješće,pronađi zemljište,",

      technology_title:
        "Tehnologija Terra Adriatice – Rješavamo složen problem vlasništva",
      technology_meta_title: "Terra Adriatica - Tehnologija",
      technology_description:
        "UI, strojno učenje, obrada prirodnog jezika, neuronske mreže, automatizacija procesa – naprednom tehnologijom rješavamo vlasničko pitanje u Hrvatskoj.",
      technology_keywords:
        "pravo na zemljište,zemljište u Hrvatskoj,terra adriatica,napredna tehnologija,ui,strojno učenje,",

      blog_title:
        "Blog Terra Adriatice – Najave, promišljanja, dodaci i savjeti\n",
      blog_meta_title: "Terra Adriatica Blog",
      blog_description:
        "Čitajte naš blog i produbite svoje razumijevanje o problemu zemljišnog vlasništva u Hrvatskoj. Saznajte zanimljive činjenice, upoznajte Tim i podijelite projekt.",
      blog_keywords:
        "terra adriatica blog,ta blog,zemljišne knjige zanimljivo,zemljišno vlasništvo hrvatska,zanimljive činjenice hrvatska,nekretnine zanimljivo,",
      blog_twitter_image: "https://terraadriatica.hr/static/images/Terra Adriatica Blog FB thumbnail.jpg",
      blog_og_image: "https://terraadriatica.hr/static/images/Terra Adriatica Blog FB thumbnail.jpg",

      about_title:
        "O projektu Terra Adriatica, motivaciji i ljudima koji ga čine",
      about_meta_title: "Terra Adriatica – O nama",
      about_description:
        "Ako vrijeme i novac nisu bitni, može li se to postići? Možemo li uspostaviti čisto zemljišno vlasništvo i otključati vrijednost zemljišta? O projektu i ljudima.",
      about_keywords:
        "pravo na zemljište,zemljište u Hrvatskoj,terra adriatica,vrijednost zemljišta hrvatska,tim terra adriatica,čisto vlasništvo,",

      faq_title:
        "FAQ Terra Adriatice – Zašto, kada i kako koristiti naše usluge",
      faq_meta_title: "Terra Adriatica - FAQ",
      faq_description:
        "Vi pitate, mi odgovaramo. Kako je naš projekt moguć, održiv i uspješan? Saznajte kako ostvariti čisto vlasništvo i prodaju zemljišta po najvišoj cijeni.",
      faq_keywords:
        "pravo na zemljište,zemljište u Hrvatskoj,terra adriatica,čisto vlasništvo,prodaja zemljišta,terra adriatica pitanja,",

      journey_zero_title: "Započnite svoje putovanje s Terra Adriaticom",
      journey_zero_meta_title: "Započnite svoje putovanje s Terra Adriaticom",
      journey_zero_description:
        "Istražite moguća vlasnička prava s Terra Adriaticom. Započnite svoje putovanje upisom prezimena i otkrijte koja su Vaša moguća prava na zemljište u Hrvatskoj.",
      journey_zero_keywords:
        "započnite svoje putovanje,vlasnička prava,zemljište u hrvatskoj,prezime,pravo na zemljište,terra adriatica,",

      tos_title: "Uvjeti korištenja usluga Terra Adriatice",
      tos_meta_title: "Terra Adriatica – Uvjeti korištenja",
      tos_description:
        "Uvjeti korištenja upravljaju korištenjem naših web stranica, usluga i mobilnih aplikacija. Pročitajte Uvjete i odredbe Terra Adriatice i saznajte svoja prava.",
      tos_keywords:
        "pravo na zemljište,zemljište u Hrvatskoj,terra adriatica,terra adriatica uvjeti korištenja,uvjeti i odredbe,",

      privacy_policy_title: "Politika privatnosti Terra Adriatice",
      privacy_policy_meta_title: "Terra Adriatica – Politika privatnosti",
      privacy_policy_description:
        "Ozbiljno shvaćamo sigurnost Vaših osobnih podataka. Postupak rješavanja vlasništva nad zemljištem zahtijeva Vaše osobne podatke – saznajte kako ih koristimo.",
      privacy_policy_keywords:
        "pravo na zemljište,zemljište u Hrvatskoj,terra adriatica,terra adriatica politika privatnosti,terra adriatica osobni podaci,terra adriatica sigurnost podataka,",

      cookie_policy_title: "Politika kolačića Terra Adriatice",
      cookie_policy_meta_title: "Terra Adriatica – Politika kolačića",
      cookie_policy_description:
        "Web stranica Terra Adriatice koristi kolačiće za poboljšanje korisničkog iskustva. Koristimododatne analitičke alate za praćenje ponašanja korisnika.",
      cookie_policy_keywords:
        "pravo na zemljište,zemljište u Hrvatskoj,terra adriatica,terra adriatica politika kolačića,terra adriatica kolačići,terra adriatica analitika",

      compliance_title: "Compliance at Terra Adriatica",
      compliance_meta_title: "Terra Adriatica Compliance",
      compliance_description: "",
      compliance_keywords: "",

    },
    "es": {
    }
  }
//</i18n>