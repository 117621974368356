
<template>
  <div class="root_cont" id="app-footer">
    <div class="container">

      <div class="button_sec">
        <div class="">
          <div class="social_icons text-center">
            <a target="_blank" href="https://facebook.com/terraadriatica.hr/">
              <svg version="1.1" fill="currentColor"
                 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <circle class="border" cx="50" cy="50" r="49" stroke-width="2" stroke="#fff"></circle>
                <g style="scale: 1; transform: translate(35px, 24px);transform-origin: 50% 50%">
                  <path d="M26.3,0.4C25.5,0.3,22.6,0,19.4,0C12.5,0,7.8,4.2,7.8,11.9v6.6H0v9h7.8v23.1h9.3v-23h7.7l1.2-9h-8.9v-5.8
                    c0-2.6,0.7-4.4,4.5-4.4h4.8L26.3,0.4L26.3,0.4z"></path>
                </g>
              </svg>
            </a>
            <a target="_blank" href="https://www.linkedin.com/showcase/terraadriatica" class="">
              <svg version="1.1" fill="currentColor" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <circle class="border" cx="50" cy="50" r="49" stroke-width="2" stroke="#fff"></circle>
                <g style="scale: .95; transform: translate(26px, 24px); transform-origin: 50% 50%">
                   <path d="M46.8,27.4c0-9.3-4.9-13.6-11.5-13.6c-5.4,0-7.8,3-9.1,5.1h0.1v-4.4h-10c0,0,0.1,2.8,0,30.2h10V27.9c0-0.9,0.1-1.8,0.3-2.5
                  c0.7-1.8,2.4-3.7,5.2-3.7c3.6,0,5.1,2.8,5.1,6.8v16.2h10L46.8,27.4L46.8,27.4z"></path>
                  <path d="M11.3,5.2c0-3-2.2-5.2-5.6-5.2C2.3,0,0,2.3,0,5.2s2.2,5.2,5.5,5.2h0.1C9.1,10.4,11.3,8.1,11.3,5.2"></path>
                  <rect x="0.6" y="14.5" width="10.1" height="30.2"></rect>
                </g>
              </svg>
            </a>
            <a target="_blank" href="https://www.instagram.com/terra_adriatica/" class="">
              <svg version="1.1" fill="currentColor" viewBox="0 0 100 100" xml:space="preserve">
                <circle class="border" cx="50" cy="50" r="49" stroke-width="2" stroke="#fff"></circle>
                <g style="scale: 1.1; transform: translate(28px, 28px); transform-origin: 50% 50%">
                  <circle cx="32.776" cy="10.165" r="2.513"/>
                  <path d="M21.605,10.875c-5.82,0-10.555,4.735-10.555,10.555s4.735,10.555,10.555,10.555S32.16,27.249,32.16,21.429
                    S27.425,10.875,21.605,10.875z M21.605,28.191c-3.728,0-6.761-3.033-6.761-6.761c0-3.728,3.033-6.761,6.761-6.761
                    c3.728,0,6.761,3.033,6.761,6.761C28.366,25.158,25.334,28.191,21.605,28.191z"/>
                  <path d="M29.985,42.859H12.874C5.775,42.859,0,37.084,0,29.985V12.873C0,5.775,5.775,0,12.874,0h17.111
                    c7.099,0,12.874,5.775,12.874,12.873v17.112C42.859,37.084,37.083,42.859,29.985,42.859z M12.874,4.032
                    c-4.876,0-8.842,3.966-8.842,8.841v17.112c0,4.876,3.966,8.841,8.842,8.841h17.111c4.875,0,8.842-3.966,8.842-8.841V12.873
                    c0-4.876-3.967-8.841-8.842-8.841H12.874z"/>
                </g>
              </svg>
            </a>
            <a target="_blank" href="https://twitter.com/TerraAdriatica_" class="">
              <svg version="1.1" fill="currentColor" viewBox="0 0 100 100" xml:space="preserve">
                <circle class="border" cx="50" cy="50" r="49" stroke-width="2" stroke="#fff"></circle>
                <g style="scale: 1.26; transform: translate(30px, 34px); transform-origin: 50% 50%">
                  <circle cx="32.776" cy="10.165" r="2.513"/>
                  <path d="M39.703,3.933c-1.266,0.573-2.666,0.92-4.032,1.149c0.648-0.109,1.6-1.283,1.982-1.757c0.583-0.724,1.066-1.549,1.338-2.44
                    c0.035-0.07,0.06-0.157-0.009-0.21c-0.087-0.035-0.144-0.017-0.214,0.018c-1.524,0.817-3.102,1.41-4.78,1.829
                    c-0.139,0.035-0.242,0-0.33-0.087c-0.139-0.157-0.27-0.305-0.418-0.427c-0.705-0.6-1.462-1.071-2.316-1.419
                    c-1.121-0.457-2.345-0.65-3.551-0.572c-1.174,0.076-2.327,0.407-3.369,0.952c-1.044,0.546-1.976,1.305-2.718,2.22
                    c-0.767,0.947-1.334,2.066-1.623,3.251c-0.28,1.144-0.257,2.276-0.086,3.433c0.026,0.192,0.009,0.218-0.165,0.192
                    C12.848,9.087,7.405,6.738,2.988,1.704C2.796,1.485,2.692,1.485,2.535,1.72c-1.933,2.898-0.995,7.553,1.42,9.841
                    c0.322,0.305,0.653,0.61,1.009,0.888c-0.13,0.027-1.737-0.148-3.169-0.888c-0.192-0.121-0.288-0.052-0.305,0.166
                    c-0.018,0.313,0.009,0.601,0.052,0.941c0.371,2.937,2.405,5.654,5.191,6.715c0.331,0.139,0.697,0.261,1.053,0.322
                    c-0.635,0.139-1.288,0.239-3.109,0.096c-0.227-0.044-0.313,0.07-0.227,0.287c1.368,3.728,4.321,4.838,6.532,5.469
                    c0.296,0.052,0.592,0.052,0.889,0.121c-0.018,0.027-0.036,0.027-0.053,0.053c-0.734,1.118-3.276,1.95-4.46,2.364
                    c-2.154,0.753-4.499,1.091-6.775,0.867c-0.364-0.054-0.441-0.05-0.54,0c-0.1,0.062-0.013,0.149,0.104,0.243
                    c0.462,0.305,0.932,0.575,1.411,0.837c1.445,0.766,2.953,1.376,4.528,1.811c8.138,2.248,17.305,0.596,23.416-5.475
                    c4.799-4.768,6.48-11.343,6.48-17.926c0-0.258,0.304-0.4,0.485-0.536c1.249-0.938,2.255-2.061,3.187-3.314
                    c0.209-0.282,0.211-0.532,0.211-0.635c0-0.018,0-0.035,0-0.035C39.867,3.828,39.865,3.859,39.703,3.933z"/>
                </g>
              </svg>
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCA_7usi7Kqu-xV67UWBUz_w" class="">
              <svg version="1.1" fill="currentColor" viewBox="0 0 100 100" xml:space="preserve">
                <circle class="border" cx="50" cy="50" r="49" stroke-width="2" stroke="#fff"></circle>
                <g style="scale: 1.4; transform: translate(30px, 36px); transform-origin: 50% 50%">
                  <path d="M38.849,4.365c-0.456-1.718-1.801-3.071-3.508-3.531C32.247,0,19.839,0,19.839,0S7.431,0,4.337,0.834
                    C2.63,1.294,1.285,2.647,0.829,4.365C0,7.48,0,13.978,0,13.978s0,6.498,0.829,9.612c0.456,1.718,1.801,3.071,3.508,3.531
                    c3.094,0.835,15.502,0.835,15.502,0.835s12.408,0,15.502-0.835c1.707-0.459,3.052-1.813,3.508-3.531
                    c0.829-3.114,0.829-9.612,0.829-9.612S39.679,7.48,38.849,4.365z M15.781,19.877V8.078l10.371,5.9L15.781,19.877z"/>
                </g>
              </svg>
            </a>
          </div>
        </div>

      </div>

      <div>
        <div class="text-center">
          <span>{{ t.ta_copy }}</span>
          <span class="vertSepr"></span>
          <span>Terralogix Group Ltd</span>
          <span class="vertSepr"></span>
          <span>{{ t.all_rights_reserved }}</span>
        </div>
      </div>

      <div class="text-center mt-2">
        <span>{{ t.ta_desc }}</span>
      </div>

      <div class="mt-3">
        <div class="text-center links" ref="links">
          <router-link :to="{name: 'about'}"><span>{{ t.about_ta }}</span></router-link>
          <span class="vertSepr"></span>
          <router-link :to="{name: 'privacy_policy'}"><span>{{ t.pp }}</span></router-link>
          <span class="vertSepr"></span>
          <router-link :to="{name: 'tos'}"><span>{{ t.toc }}</span></router-link>
          <span class="vertSepr"></span>
          <router-link :to="{name: 'disclaimer'}"><span>{{ t.disclaimer }}</span></router-link>
          <span class="vertSepr"></span>
          <router-link :to="{name: 'compliance'}"><span>{{ t.compliance }}</span></router-link>
          <span class="vertSepr"></span>
          <router-link :to="{name: 'cookie_policy'}"><span>{{ t.cp }}</span></router-link>
          <span class="vertSepr"></span>
          <a href="/static/sitemap.xml"><span>{{ t.sm }}</span></a>
          <span class="vertSepr" style="background-color: rgb(4,43,93)"></span>
        </div>
      </div>

    </div>
    <Cookies></Cookies>
  </div>
</template>

<script>
  const t =
    //<i18n>
{
  "en": {
    "sub_to_news": "SUBSCRIBE TO OUR NEWSLETTER",
    "ta_copy": "Copyright © 2021",
    "all_rights_reserved": "All rights reserved",
    "ta_desc": "Terra Adriatica Solutions d.o.o. trading as Terra Adriatica is a subsidiary of Terralogix Group Ltd",
    "about_ta": "About Terra Adriatica",
    "pp": "Privacy Policy",
    "toc": "Terms and Conditions of Use",
    "cp": "Cookie Policy",
    "compliance": "Compliance",
    "disclaimer": "Subscriptions Services Disclaimer",
    "sm": "Site Map"
  },
  "hr": {
    "sub_to_news": "PRIJAVITE SE NA NEWSLETTER",
    "ta_copy": "Copyright © 2021",
    "all_rights_reserved": "Sva prava pridržana",
    "ta_desc": "Terra Adriatica je projekt društva Terra Adriatica Solutions d.o.o. u vlasništvu Terralogix Group Ltd.",
    "about_ta": "O Terra Adriatici",
    "pp": "Politika privatnosti",
    "toc": "Uvjeti i odredbe korištenja",
    "cp": "Politika kolačića",
    "compliance": "Usklađenost",
    "disclaimer": "Uvjeti usluga pretplate",
    "sm": "Site Map"
  },
  "es": {
    "sub_to_news": "SUSCRÍBASE A NUESTRO BOLETÍN",
    "ta_copy": "Copyright © 2021",
    "all_rights_reserved": "Todos los derechos reservados",
    "ta_desc": "Terra Adriatica Solutions d.o.o. que opera bajo el nombre comercial de Terra Adriatica es una subsidiaria de Terralogix Group Ltd",
    "about_ta": "Sobre Terra Adriatica",
    "pp": "Política de Privacidad",
    "toc": "Términos y Condiciones de uso",
    "cp": "Política de Cookies",
    "sm": "Mapa del sitio web"
  }
}
//</i18n>

  import Cookies from "./Cookies"

  export default {
    name: "Footer",
    components: {Cookies},
    data() {
      return {
        t: t[this.$lang]
      }
    },
    mounted() {this.setupPipes()},
    methods: {
      setupPipes() {
        const links = this.$refs.links,
            pipes = links.querySelectorAll(".vertSepr")

        for (let i = 1; i < pipes.length; i++) {
          const last_pipe = pipes[i-1],
              last_pipe_x = last_pipe.getBoundingClientRect().left,
              pipe = pipes[i],
              pipe_x = pipe.getBoundingClientRect().left;

          if (pipe_x < last_pipe_x) last_pipe.style.opacity = 0;
          else last_pipe.style.opacity = 1
        }
      }
    },
    watch: {
      "$store.state.responsive.window_width"() {this.setupPipes()}
    }
  }
</script>

<style scoped>

  .root_cont {
    background-color: #172645;
    color: #fff;
    font-size: 13px;
    position: relative;

    padding: 50px 0 62px;
  }

  .social_icons svg {
    width: 39px;
  }

  .social_icons circle.border {
    fill: none;
    transition: .3s;
  }

  .social_icons a {
    margin-left: 20px;
    transition: .3s;
  }

  .social_icons a:first-child {
    margin-left: 0;
  }

  .social_icons a:hover {
    color: rgb(4, 43, 93);
  }

  .social_icons a:hover circle.border {
    fill: #fff;
  }

  .button_sec {
    position: relative;
    padding: 0 0 30px 0;
  }

  button {
    padding: 10px;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    font-weight: 400;
    vertical-align: bottom;
  }

  .button_slide {
    box-shadow: inset 0 0 0 0 #fff;
  }

  .button_slide:hover {
    box-shadow: inset 400px 0 0 0 #fff;
    color: black !important;
  }

  .sub_btn {
    float: right;
  }

  .links {
    max-width: 750px;
    margin: auto;
  }

  .vertSepr {
    display: inline-block;
    background-color: #fffff3;
    width: .1em;
    height: 1.2em;
    position: relative;
    top: .3em;
    margin: 0 .3em;
  }

  a {
    color: white;
    text-decoration: underline;
    white-space: nowrap;
  }

  @media (max-width: 576px) {
    .sub_btn {
      margin-top: 16px;
      float: none;
    }

    .button_sec {
      text-align: center;
    }
  }

  @media (max-width: 360px) {
    .social_icons a {
      margin-left: 15px;
    }

    .social_icons svg {
      width: 30px;
    }
  }


</style>
