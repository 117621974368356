import {apiFetchDelete, apiFetchGet, apiFetchPost} from "@/api/handler"

const state = () => ({
  ordered: false,
  created: false,
  result: false,
  order_failed: false
})

const actions = {
  getData({dispatch}) {
    return apiFetchGet("/api/family-tree")
      .then(response => response.json())
      .then(data => {
        const family_tree_json = data.family_tree_json || {};
        dispatch("updateState", family_tree_json)
      });
  },
  updateState({commit, dispatch}, status) {
    commit("mutateState", status)
  },
  order({commit, dispatch}) { // todo: do I need it
    const new_report_state = JSON.parse(JSON.stringify(this.state));
    new_report_state.ordered = true;
    new_report_state.created = false;
    commit("mutateState", new_report_state)

    return apiFetchPost("/api/portal/premium-report/order")
      .then(response => {
        if (response.status !== 200) {
          commit("mutateOrderFailed", true)
          throw "api rejects the call";
        }
      })
      .finally(() => dispatch("profile/getInfo", null, {root: true}));
  },
  createAndSetupOnBE({}, language, unit_system) {
    return apiFetchPost('/api/portal/premium-report/setup', {language, unit_system}, {obj: true})
  },
  webshopSuccess() {
    return apiFetchGet('/api/portal/premium-report/webshop-success')
      .then(() => dispatch("profile/getInfo", null, {root: true}))
      .then(() => apiFetchPost('/api/portal/premium-report/order'))
      .then(() => dispatch("profile/getInfo", null, {root: true}))
  },
  deleteForTests() {
    return apiFetchDelete("/api/portal/premium-report/delete")
      .catch(reason => console.log(reason))
  }
}

const getters = {
  report_url() {
    return () => apiFetchGet("/api/portal/premium-report/download-pdf")
      .then(response => {
        if (response.status !== 200) throw "no report for you";
        else return response;
      })
      .catch(reason => console.log(reason))
  },
  webshopOrderParams() {
    return () => new Promise(resolve => {
      return apiFetchPost('/api/portal/premium-report/webshop-order-params')
        .then(response => response.json())
        .then(response_json => resolve(response_json))
    })
  },
}

const mutations = {
  mutateState(state, status) {
    state.ordered = status.ordered
    state.created = status.created
    state.result = status.result
  },
  mutateOrderFailed(state, new_value) {
    state.order_failed = new_value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
