export function groupParcels(parcels) {

  const groups = {}
  for (let i = 0; i < parcels.length; i++) {
    const d = parcels[i];
    const k = d.scoreid + '_' + d.rel
    if (!groups.hasOwnProperty(k)) groups[k] = []
    groups[k].push(d)
  }
  const parcels_grouped = Object.values(groups).map(formatDataToGroupParcel)
  createKoCoor(parcels_grouped)

  return parcels_grouped

  function formatDataToGroupParcel(group) {
    const representative = group[0];

    const area_m2 = group.reduce((acc, d) => (acc += (d.pl_povrsina || d.zk_povrsina) || 0), 0),
      geometries = group.map(d => d.geometry instanceof Object ? d.geometry : parseSafe(d.geometry)).filter(d => d),
      zk_bkcs = group.map(d => d.zk_bkc).filter(v => v)

    return Object.assign({geometries, area_m2, zk_bkcs}, representative)

    function parseSafe(str) {
      try {
        return JSON.parse(str)
      } catch (e) {}
    }
  }

  function createKoCoor(parcels) {
    const mainbookids = {};

    parcels.forEach(d => {
      if (mainbookids[d.mainbookid]) return
      mainbookids[d.mainbookid] = d.geometries && d.geometries.length > 0 ? firstCoor(d.geometries[0].coordinates) : null;
    })

    parcels.forEach(d => d.ko_coor = mainbookids[d.mainbookid])

    function firstCoor(d) {
      while (Array.isArray(d[0])) d = d[0]
      return d
    }
  }
}

export function createDataByRels(parcels, family_tree) {
  const structured_data = formatDataByRelatives(parcels);
  Object.values(structured_data).forEach(d => {d.parcels = d.parcels.sort(sortByAiScore).slice(0, 100)})

  return structured_data

  function formatDataByRelatives(parcels) {
    const all_rel_types = ["main", "f", "m", "ff", "fm", "mf", "mm"],
      data = {};

    all_rel_types.forEach(rel_type => {
      if (!family_tree[rel_type] || Object.keys(family_tree[rel_type]).length === 0) return
      data[rel_type] = {rel_type, parcels: [], personal_data: family_tree[rel_type]}
    })

    parcels.forEach(d => {
      const rel_type = d.rel;
      if (data.hasOwnProperty(rel_type)) data[rel_type].parcels.push(d);
    })

    return data
  }

  function sortByAiScore(a, b) {
    return b.srch_score - a.srch_score
  }
}
