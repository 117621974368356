import {TOKEN_KEY, TokenService} from "@/services/storage.service"
import {authWithOneTimeToken, checkAuthStatusApi, sendEmailLoginLink} from "@/api/auth"
import router from "@/router"
import {apiFetchDelete} from "@/api/handler"

const state = () => ({
  user_logged_in: false,
  modal_open: false,
  form_type: 1 // 1 = login, 2 = register
})


// actions
const actions = {
  sendEmailLoginLink({}, formData) {return sendEmailLoginLink(formData)},
  authWithOneTimeToken({}, token, resend_link) {return authWithOneTimeToken(token, resend_link)},
  updateToken({commit}, data) {
    TokenService.saveToken(data[TOKEN_KEY]);
    commit("mutateUserAuthStatus", true)
  },
  logout({commit, dispatch}) {
    TokenService.saveToken("");
    commit("mutateUserAuthStatus", false);
  },
  checkAuthStatus({commit, dispatch}) {
    return checkAuthStatusApi()
      .then(response => {
        if (response.status === 200) commit("mutateUserAuthStatus", true)
        else {
          TokenService.saveToken("");
          commit("mutateUserAuthStatus", false);
        }
      })
      .catch(console.log);
  },
  changeModalStatus({commit, state}, new_value) {
    commit("mutateModalStatus", new_value)

    if (!state.modal_open) history.replaceState({}, "", router.currentRoute.value.fullPath);
  },
  switchFormType({commit, state}, form_type) {
    commit("mutateFormType", form_type)
    const form_name = getFormRouteName(form_type)

    if (state.modal_open) {
      history.replaceState({}, "", router.resolve({ name: form_name }).href);
    } else {
      router.push({ name: form_name });
    }
  },
  openAuth({commit, dispatch, rootGetters}, form_type) {
    return dispatch('core/updateModalUnavailableOpen', true, {root: true})
    if (
      !rootGetters["responsive/isWindowStateLessThen"]("md") &&
      ![1,2,3].find(n => getFormRouteName(n) === router.currentRoute.value.name)
    ) {
      dispatch("changeModalStatus", true)
    }
    dispatch("switchFormType", form_type)
  },
  deleteAccount({dispatch}) {
    apiFetchDelete("/api/auth/delete-account")
      .then(response => {
        if (response.status === 200) {
          dispatch("logout");
          router.push({name: "home", query: {"del-acc": "true"}});
        }
      });
  }
}

// mutations
const mutations = {
  mutateUserAuthStatus(state, new_value) {
    state.user_logged_in = new_value;
  },
  mutateModalStatus(state, new_value) {
    state.modal_open = new_value
  },
  mutateFormType(state, new_value) {
    state.form_type = new_value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

function getFormRouteName(form_type) {
  return [null, "login", "registration"][form_type]
}
