export function userTracking(route) {
  addCookieIfNot('first_visit', route.fullPath)
  if (route.meta.is_landing_page && route.meta.campaign === 'usa_direct') {
    // tracking only landing page for usa direct because of onetime code connection with potential users.
    // in future, if other pages needs to be tracked to something else. maybe track all...
    addCookieIfNot('landing_page', route.fullPath)
  }
}

function addCookieIfNot(cookie_name, route_path) {
  if (route_path.length > 200) route_path = route_path.slice(0, 197)+'...'  // on backend is max_char set to 240
  if (!getCookie(cookie_name))
    document.cookie = `${cookie_name}=${route_path}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}