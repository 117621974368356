import router from "@/router"
import {userTracking} from "@/store_vuex/handlers/core.userTracking"
import {TokenService} from "@/services/storage.service"
import {importF3} from "@/store_vuex/handlers/translate_ta_data"

const state = () => ({
  initialized: -1,
  rerender: 0,
  tab_active: "home",

  modal_unavailable_open: false
})

const mutations = {
  mutateInitialized(state, value) {state.initialized = value},
  mutateRerender(state, value) {state.rerender = value},
  mutateActiveTab(state, value) {state.tab_active = value},

  mutateModalUnavailableOpen(state, value) {state.modal_unavailable_open = value},
}

const actions = {
  async initialAppSetup({ commit, state, rootState, dispatch }) {
    commit('mutateInitialized', 0)
    setupWindowResizeListener(dispatch)
    userTracking(router.currentRoute.value)
    importF3()
    await dispatch("auth/checkAuthStatus", null, { root: true });
    await dispatch("lang/getAppLang", null, { root: true })

    const route = router.currentRoute.value;
    if (!route.params.lang)
      await router.replace({ path: "/" + rootState.lang.value + route.fullPath, query: route.query, params: route.params });
    else if (route.params.lang !== rootState.lang.value)
      await dispatch("lang/change", route.params.lang, {root: true})

    commit('mutateInitialized', 1)
  },
  rerender({commit}) {commit("mutateRerender", Math.random())},
  updateActiveTab({commit}, tab) {commit("mutateActiveTab", tab)},
  updateModalUnavailableOpen({commit}, status) {commit("mutateModalUnavailableOpen", status)},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

function setupWindowResizeListener(dispatch) {
  let timeout = setTimeout(()=>{}, 1)
  window.addEventListener("resize", () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => dispatch("responsive/updateWindowWidth", null, {root: true}), 1000)
  });
}