<template>
  <div v-if="show_modal" class="modal" @click="closeForm($event)">
    <div class="background_overlay" ref="overlay"></div>
    <div class="modal-container" ref="modal_container">
      <div class="modal-body">
        <span class="close_btn" ref="modal_close_btn" v-if="!no_close_btn" :style="custom_style && custom_style.close_btn">
          <svg-close-x style="pointer-events: none;"></svg-close-x>
        </span>
        <div class="main_cont" :style="{width: (width || 400)+'px', ...(custom_style ? custom_style.main_cont : {})}">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["show_modal", "width", "closeModal", "no_close_btn", "custom_style"],
  methods: {
    closeForm(e) {
      const vm = this;

      if (
        e.target !== vm.$el &&
        e.target !== vm.$refs.modal_container &&
        e.target !== vm.$refs.modal_close_btn &&
        e.target !== vm.$refs.overlay
      )
        return;

      vm.$emit("input", false);
      if (vm.closeModal) vm.closeModal()
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  z-index: 10;
}

div.background_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;

  opacity: 0.4;
  /*background-image: url("../../assets/to_delete/PNGforRegisterPlavi.png");*/
  background-color: rgb(4, 43, 93); /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.modal .modal-container {
  position: relative;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.modal .modal-body {
  border-radius: 1em;
  display: inline-block;
  background-color: white;
  padding: 0;
  max-width: 95vw;
  text-align: left;
  max-height: 85vh;

  overflow-x: hidden;
  overflow-y: auto;

}

.main_cont {
  max-width: 100%;
  padding: 40px 40px 20px;
  color: rgb(4, 43, 93);

  text-align: center;

  border-radius: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main_cont h3 {
  font-weight: 400;
}

.close_btn {
  width: 24px;
  height: 24px;
  position: absolute;
  display: block;
  padding: 5px;
  top: 12px;
  right: 11px;
  z-index: 3;
  cursor: pointer;
  color: #999;
}

.close_btn:hover {
  color: rgb(4, 43, 93);
}
</style>
