import t from './routes_seo_texts';

function getMetaTags(route_name) {
  if (!t.en[route_name+"_title"]) return null
  return {
    en: {
      title: t.en[route_name+"_title"],
      meta_title: t.en[route_name+"_meta_title"],
      description: t.en[route_name+"_description"],
      keywords: t.en[route_name+"_keywords"],
      twitter_image: t.en[route_name+"_twitter_image"],
      og_image: t.en[route_name+"_og_image"]
    },
    hr: {
      title: t.hr[route_name+"_title"],
      meta_title: t.hr[route_name+"_meta_title"],
      description: t.hr[route_name+"_description"],
      keywords: t.hr[route_name+"_keywords"],
      twitter_image: t.hr[route_name+"_twitter_image"],
      og_image: t.hr[route_name+"_og_image"]
    }
  }
}

const routes = [
  {
    path: "/:lang(en|hr|es)",
    components: {root: () => import("@/views/MainRouterView.vue")},
    children: [
      {path: "login", redirect: {name: "home"}},
      {path: "registration", redirect: {name: "home"}},

      // {path: "login", name: "login", components: {main: () => import("@/views/auth/LoginView.vue")}, meta: {public: true, meta_tags: getMetaTags("login")}},
      // {path: "registration", name: "registration", components: {main: () => import("@/views/auth/RegistrationView.vue")}, meta: {public: true, meta_tags: getMetaTags("registration")}},
      // {path: "otp_auth", name: "otp_auth", components: {main: () => import("@/views/auth/OTPAuthView.vue")}, meta: {public: true}},
      // {path: "otp_auth_ver", name: "otp_auth_ver", components: {main: () => import("@/views/auth/OTPAuthView.vue")}, meta: {public: true}},
      {path: "unsubscribe", name: "unsubscribe", components: {main: () => import("@/views/auth/UnsubscribeView")}, meta: {public: true}},
      {path: "mc-unsubscribe-success", name: "unsubscribe_mc", components: {main: () => import("@/views/auth/McUnsubscribeSuccessView")}, meta: {public: true}},
      {path: "mc-subscribe-success", name: "subscribe_success_mc", components: {main: () => import("@/views/auth/McSubscribeSuccessView")}, meta: {public: true}},
      // {path: "webshop", name: "webshop", components: {main: () => import("@/views/web/WebShop.vue")}, meta: {public: false}},
      // {path: "webshop/success", name: "webshop_success", components: {main: () => import("@/views/web/WebShopSuccess.vue")}, meta: {public: false}},

      {path: "", name: "home", components: {main: () => import("@/views/web/Home.vue")}, meta: {public: true, meta_tags: getMetaTags("home")}},
      {path: "locksmith", name: "locksmith", components: {main: () => import("@/views/web/Locksmith")}, meta: {public: true, meta_tags: getMetaTags("locksmith")}},
      {path: "solutions", redirect: {name: "locksmith"}},
      {path: "technology", name: "technology", components: {main: () => import("@/views/web/Technology.vue")}, meta: {public: true, meta_tags: getMetaTags("technology")}},
      {path: "reports", name: "reports", components: {main: () => import("@/views/web/Reports")}, meta: {public: true, meta_tags: getMetaTags("reports")}},
      {path: "blog", name: "blog", components: { main: () => import("@/views/web/Blog.vue")}, meta: {public: true, meta_tags: getMetaTags("blog")}},
      {path: "about", name: "about", components: {main: () => import("@/views/web/About.vue")}, meta: {public: true, meta_tags: getMetaTags("about")}},
      {path: "faq", name: "faq", components: {main: () => import("@/views/web/FAQ.vue")}, meta: {public: true, meta_tags: getMetaTags("faq")}},

      {path: "journey", redirect: {name: "home"}},
      // {path: "journey", name: "journey_zero", components: {main: () => import("@/views/web/JourneyZero.vue")}, meta: {public: true, meta_tags: getMetaTags("journey")}},
      // {path: "journey/:surname", name: "journey", components: {main: () => import("@/views/web/JourneyView.vue")}, meta: {public: true}},
      {path: "journey-report-extract", name: "journey_report_extract", components: {main: () => import("@/views/web/JourneyReportExtract")}, meta: {public: true}},

      // {path: "portal", name: "portal_home", components: {main: () => import("@/views/portal/PortalHome.vue")},
      //   children: [
      //     {path: "profile", name: "portal_profile", components: {portal: () => import("@/views/portal/PortalProfile.vue")}},
      //     {path: "family-tree", name: "portal_family_tree", components: {portal: () => import("@/views/portal/PortalFamilyTree.vue")}},
      //     {path: "locksmith", name: "portal_locksmith", components: {portal: () => import("@/views/portal/PortalLocksmith.vue")}},
      //     {path: "reports", name: "portal_reports", components: {portal: () => import("@/views/portal/PortalReports.vue")}},
      //     {path: "refine-results", name: "portal_refine_results", components: {portal: () => import("@/views/portal/PortalRefineResults")}},
      //     {path: "my-locksmith", name: "portal_my_locksmith", components: {portal: () => import("@/views/portal/PortalMyLocksmith")}},
      //   ], meta: {public: false}
      // },

      {path: "start", name: "start", components: {main: () => import("@/views/landing_page/LandingPageStart")}, meta: {public: true, is_landing_page: true,}},
      {path: "landing-page", name: "landing_page", components: {main: () => import("@/views/landing_page/LandingPageStart")}, meta: {public: true, is_landing_page: true,}},
      {path: "landing-page/GoT", name: "landing_page_got", components: {main: () => import("@/views/landing_page/LandingPageGoT.vue")}, meta: {public: true, is_landing_page: true,}},
      {path: "croatian-land-rights", name: "croatian_land_rights", components: {main: () => import("@/views/auth/RegistrationView.vue")}, meta: {public: true, is_landing_page: true, is_registration: true}},
      {path: "start_aff08", name: "start_aff08", components: {main: () => import("@/views/landing_page/LandingPageStart")}, meta: {public: true, is_landing_page: true, registration_route_name: 'croatian_land_rights_aff08'}},
      {path: "croatian-land-rights_aff08", name: "croatian_land_rights_aff08", components: {main: () => import("@/views/auth/RegistrationView.vue")}, meta: {public: true, is_landing_page: true, is_registration: true}},

      {path: "start-aff", name: "start_aff", components: {main: () => import("@/views/landing_page/LandingPageAffiliate")}, meta: {public: true, is_landing_page: false,}},
      {path: "USA", name: "USA", components: {main: () => import("@/views/landing_page/LandingPageStart")}, meta: {public: true, is_landing_page: true,}},
      {path: "search", name: "USA_search", components: {main: () => import("@/views/landing_page/LandingPageUSA")}, meta: {public: true, is_landing_page: true, campaign: "usa_direct"}},

      {path: "locksmith_explained", name: "locksmith_explained", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: "hUNSERBxfjVanKCM", hr: "9BJ5Mkmr023jnVNL"}}},
      {path: "free_report", name: "free_report", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: "efWypzXgamO2K5b9", hr: "kOgcLW3h2jXSTFUs"}}},
      {path: "premium_report", name: "premium_report", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: "9f1n3oaeCptqvWNO", hr: "6LcKC0zS48TZMRrp"}}},
      {path: "code_of_ethics", name: "code_of_ethics", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: "YhEFgLSBtZpy8wM7", hr: "EFqt7BM1uwdzvyHe"}}},
      {path: "suvlasnici", name: "suvlasnici", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: null, hr: "Y2kBpaTHW4cRwS83"}}},

      {path: "tos", name: "tos", components: {main: () => import("@/views/policies/TermsAndConditions.vue")}, meta: {public: true, meta_tags: getMetaTags("tos")}},
      {path: "privacy-policy", name: "privacy_policy", components: {main: () => import("@/views/policies/PrivacyPolicy.vue")}, meta: {public: true, meta_tags: getMetaTags("privacy_policy")}},
      {path: "california-policy", name: "california_policy", components: {main: () => import("@/views/policies/CaliforniaPolicy")}, meta: {public: true, meta_tags: getMetaTags("privacy_policy")}},
      {path: "cookie-policy", name: "cookie_policy", components: {main: () => import("@/views/policies/CookiePolicy.vue")}, meta: {public: true, meta_tags: getMetaTags("cookie_policy")}},
      {path: "compliance", name: "compliance", components: {main: () => import("@/views/policies/Compliance")}, meta: {public: true, meta_tags: getMetaTags("compliance")}},
      {path: "gmap-parcel-view", name: "GmapParcelView", components: {main: () => import("@/components/portal/parcels/GmapParcelView")}, meta: {public: true,}},
      {path: "diaspora_usa", name: "documentation_us_affiliates_brochure", components: {main: () => import("@/views/web/documentation/US_affiliates_brochure")}, meta: {public: true,}},
      {path: "disclaimer", name: "disclaimer", components: {main: () => import("@/views/policies/Disclaimer.vue")}, meta: {public: true, meta_tags: getMetaTags("disclaimer")}},

      {path: "publikacija", name: "PublicationCroDiasBook", components: {main: () => import("@/views/web/PublicationCroDiasBook.vue")}, meta: {public: true, hide_tabs: true}},
      {path: "book-I", name: "book_I", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: "", hr: "RzXDeQVTynoKgfGs"}, hide_tabs: true}},
      {path: "book-II", name: "book_II", components: {main: () => import("@/views/web/YumpuView")}, meta: {public: true, yumpu_embeded_id: {en: "", hr: "nA7BT9XM0zh6fpYl"}, hide_tabs: true}},


      {path: "*", redirect: { name: "home" }}
    ]
  }
];

if (process.env.VUE_APP_BRANCH_NAME === "development") {
  // routes[0].children.find(d => d.name === "portal_home").children.push(
  //   {path: "parcels", name: "portal_parcels", components: {portal: () => import("@/components/portal/parcels/PortalParcels")}}
  // )
}

export default routes;
