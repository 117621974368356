<template>
  <div>
    <div class="survey_cont" @click="() => show_modal = !show_modal" v-if="!survey_completed">
      <div class="cloud_text" :style="{paddingTop: $lang === 'hr' ? '8px' : null}"><span>{{ t.cloud_text_1 }}</span><br><span>{{ t.cloud_text_2 }}</span><br><span>{{ t.cloud_text_3 }}</span><br><span class="fw-400">{{ t.cloud_text_4 }}</span></div>
      <img class="robot_svg" src="/static/images/svg/Survey_RobotTriKrivulje.svg" alt="">
      <svg viewBox="0 0 55.8510017 62.5040016" class="cloud_svg">
        <path d="M45.354187,22.6774025c0-12.5239868-10.1530151-22.677002-22.677002-22.677002
	s-22.677002,10.1530151-22.677002,22.677002c0,12.451416,10.0368042,22.5548096,22.4606323,22.6715088l-0.0089722,0.0054932
	H37.539856l-5.0220337-2.2409668C40.1133423,39.449192,45.354187,31.6753273,45.354187,22.6774025z"/>
      </svg>
    </div>
    <div class="survey_modal_cont">
      <Modal :show_modal="show_modal" :closeModal="()=>show_modal=false" width="350">
        <div style="padding: 35px 25px 10px">
          <img src="/static/images/svg/Survey_Icon.svg" alt="" style="width: 70px; margin-left: 6%">
          <div class="mt-4 mb-4">
            <p v-if="!survey_completed">{{ t.modal_text_1 }}</p>
            <p v-else>{{ t.modal_text_2 }}</p>
          </div>
          <a class="btn btn_green button_slide_white w-100" target="_blank"
             v-if="!survey_completed" @click="surveyCompleted"
             :href="$lang === 'hr'
               ? 'https://docs.google.com/forms/d/e/1FAIpQLSdh8iY-S82kgM8vtJDdhf1MKOe845gtMpAPhsHXYILBRdMG3Q/viewform?usp=sf_link'
               : 'https://docs.google.com/forms/d/e/1FAIpQLScPPdFApA3qJxLys1sc_Dp4N7z3vGJZ0BqaMdUkkGW6YKaJKw/viewform?usp=sf_link'"
          >{{ t.btn_text }}</a>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Elements/Modal"
import {TweenMax} from "gsap/all"

const t =
//<i18n>
    {
      "en": {
        cloud_text_1: 'Do you have',
        cloud_text_2: '3 minutes',
        cloud_text_3: 'to spare?',
        cloud_text_4: 'Be a friend!',
        modal_text_1: 'Please help us understand the demand for new services by participating in our ' +
            'research – it should take no more than 3 minutes of your time.',
        modal_text_2: 'You made it! Thanks for your help!',
        btn_text: 'TAKE THE 3-MINUTE SURVEY'
      },
      "hr": {
        cloud_text_1: '',
        cloud_text_2: 'Imaš li',
        cloud_text_3: '3 minute?',
        cloud_text_4: 'Budi frend!',
        modal_text_1: 'Molimo da nam pomognete razumjeti potražnju za novim uslugama sudjelovanjem u našem ' +
            'istraživanju - ne bi Vam smjelo oduzeti više od 3 minute vremena.',
        modal_text_2: 'Uspjeli ste! Hvala Vam na pomoći!',
        btn_text: 'ISPUNI 3-MINUTNI UPITNIK'
      }
    }
//</i18n>

export default {
  name: "Survey_Float",
  components: {Modal},
  data() {
    return {
      t: t[this.$lang],

      survey_completed: null,
      show_modal: false
    }
  },
  mounted() {
    this.survey_completed = !!getCookie('survey_completed')
    if (this.survey_completed) return
    else animate()

    function animate() {
      const dly = 3;
      TweenMax.from('.survey_cont .robot_svg', 1, { x: 30, y:30, alpha: 0, delay: dly});
      TweenMax.from('.survey_cont .cloud_svg', 1, {x: 30, y:30, alpha: 0, delay: dly+1});
      TweenMax.from('.survey_cont .cloud_text', 1, {alpha: 0, delay: dly+2});

      TweenMax.set('.survey_cont', {opacity: 1});
    }
  },
  methods: {
    surveyCompleted() {
      setCookie('survey_completed', true, 365)
      this.survey_completed = true;
    }
  }
}

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
</script>

<style scoped lang="scss">
 .survey_cont {
   position: fixed;
   z-index: 2;
   bottom: 30px;
   right: 30px;
   cursor: pointer;
   opacity: 0;
   transition: .3s;
   &:hover {transform: scale(1.04)}

   @media (max-width: 576px) {bottom: 10px;right: 10px;}

 }

 .robot_svg {
   width: 150px;
   @media (max-width: 576px) {width: 135px;}
   position: absolute;
   right: 0;
   bottom: 0;
 }

 .cloud_svg {
   width: 150px;
   fill: #02ac25;
   @media (max-width: 576px) {width: 135px;}
 }

 .cloud_text {
   z-index: 1;
   color: #fff;
   font-weight: 600;
   font-size: 14px;
   position: absolute;
   top: 0;
   left: 0;
   padding: 18px 0 0 20px;
   width: 120px;
   @media (max-width: 576px) {
     font-size: 13px;
     width: 110px;
     padding: 18px 0 0 18px;
   }
 }

 .btn_green {
   border-color: #02ac25;
   background-color: #02ac25;
   color: #fff;
   &:hover{color: #02ac25!important;}
 }

 a {
   text-decoration: none;
 }


 hr {
   border: 0;
   outline: 0;
   height: 1px;
   margin: 0;
   background-color: #9faec1;
 }

</style>

<style lang="scss">
  .survey_modal_cont {
    .modal-body .main_cont {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

</style>