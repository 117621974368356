export default function translate_ta_data(data_ta, data_ca) {
  const rel_types = ["main", "f", "m", "ff", "fm", "mf", "mm"]

  for (let i = 0; i < rel_types.length; i++) {
    if (!data_ta[rel_types[i]] || Object.keys(data_ta[rel_types[i]]).length === 0) continue

    const rel_type = rel_types[i],
      ta_datum = data_ta[rel_type],
      ca_datum = getOrCreateCaDatum(ta_datum, rel_type);

    Object.keys(ta_datum)
      .filter(k => !['id', 'fathers_name'].includes(k))
      .forEach(k => ca_datum.data[k] = ta_datum[k])
  }

  return data_ca

  function getOrCreateCaDatum(ta_datum, person_rel_type) {
    const ca_datum = data_ca.find(d => d.id === ta_datum.id)
    if (ca_datum) return ca_datum
    else {
      const fd = rt => data_ca.find(d => d.id === data_ta[rt].id),
        rel_type = {
          "main": null, "f": "father", "m": "mother", "ff": "father", "fm": "mother", "mf": "father", "mm": "mother"
        }[person_rel_type],
        rel_datum = {
          "main": null, "f": fd('main'), "m": fd('main'), "ff": fd('f'), "fm": fd('f'), "mf": fd('m'), "mm": fd('m')
        }[person_rel_type]
      const ca_datum = f3.handlers.createNewPersonWithGenderFromRel({rel_type, rel_datum})
      f3.handlers.addNewPersonAndHandleRels({data_stash: data_ca, datum: ca_datum, rel_type, rel_datum})
      return ca_datum
    }
  }
}

export async function importF3() {
  window.d3 = await import("d3");
  if (window.location.hostname === "localhost") window.f3 = await import("/home/donat/Desktop/PROJECTS/Datafull/family-chart/src/index.js").then(module => module.default);
  else if (window.location.hostname.indexOf("development") !== -1) await initializeAfterScriptLoad("https://cdn.jsdelivr.net/gh/donatso/family-chart-dist/family-chart.js", "fws3", "f3")
  else await initializeAfterScriptLoad("https://cdn.jsdelivr.net/gh/donatso/family-chart-dist/family-chart.js", "fws3", "f3")


  function initializeAfterScriptLoad(src, id, window_key) {
    loadScript(src, id);
    return new Promise(resolve => {
      const check = () => {
        if (window[window_key]) {
          resolve()
        } else setTimeout(check, 300)
      }
      check()
    })

    function loadScript(src, id) {
      if (document.head.querySelector("#"+id)) return
      const script = document.createElement("script")
      script.setAttribute('id', id)
      script.src = src
      document.head.appendChild(script);
    }
  }
}

