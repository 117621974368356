<template>
  <div class="text-left">
    <div class="main_cont">
      <div class="row">
        <div class="col-md-5 no-pr">
          <div
            class="side_cont"
            :style='{"background-image": "url(\"/static/images/" + (is_landing_page ? "LandingPageCover.jpg" : "RegistrationCover.jpg") + "\")"}'
          >
            <h3 class="mb-5">
              <span v-html="is_landing_page ? t.left_head_landing_page : t.left_head"></span>
            </h3>
            <div>
              <div class="side_point">
                <div class="side_img_cont"><svg-family-tree /></div>
                <div class="text"><span v-html="t.left_p_1"></span></div>
              </div>
              <div class="side_point">
                <div class="side_img_cont"><svg-reports-icon /></div>
                <div class="text"><span v-html="t.left_p_2"></span></div>
              </div>
              <div class="side_point">
                <div class="side_img_cont"><svg-locksmith-icon /></div>
                <div class="text"><span v-html="t.left_p_3"></span></div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-7 no-pl">
          <div class="reg_cont">
            <h3>
              <span v-html="t.register"></span>
            </h3>

            <div>
              <form method="post" @submit="register($event)" class="">
                <div>
                  <input
                    type="hidden"
                    name="language"
                    :value="$lang"
                  />
                </div>
                <div>
                  <div class="inputContainer">
                    <label for="name" class="labelContainer">
                      <input
                        @input="onFormFieldChange($event.target)"
                        type="text"
                        id="name"
                        placeholder=" "
                        name="first_name"
                      />
                      <span class="label">
                        <span v-html="t.name"></span>
                      </span>
                      <span class="border"></span>
                    </label>
                  </div>
                  <span v-for="err in errors.first_name" class="inp_error">{{
                    err
                  }}</span>
                </div>
                <div>
                  <div class="inputContainer">
                    <label for="last_name" class="labelContainer">
                      <input
                        @input="onFormFieldChange($event.target)"
                        type="text"
                        id="last_name"
                        placeholder=" "
                        name="last_name"
                      />
                      <span class="label">
                        <span v-html="t.last_name"></span>
                      </span>
                      <span class="border"></span>
                    </label>
                  </div>
                  <span v-for="err in errors.last_name" class="inp_error">{{
                    err
                  }}</span>
                </div>
                <div>
                  <div class="inputContainer country">
                    <select
                      @change="onFormFieldChange($event.target)"
                      name="country_of_residence"
                      id="country_of_residence"
                    >
                      <option value="" disabled selected hidden></option>
                      <option v-for="d in countries" :value="d.abbreviation"
                        >{{ d.country }}
                      </option>
                    </select>
                    <span class="label">
                      <span v-html="t.country"></span>
                    </span>
                    <span class="caret_down"
                      ><img src="/static/images/Arrow_blue.svg" alt=""
                    /></span>
                    <span class="border"></span>
                  </div>
                  <span
                    v-for="err in errors.country_of_residence"
                    class="inp_error"
                    >{{ err }}</span
                  >
                </div>
                <div>
                  <div class="inputContainer">
                    <label for="email" class="labelContainer">
                      <input
                        @input="onFormFieldChange($event.target)"
                        type="email"
                        id="email"
                        placeholder=" "
                        name="email"
                        required
                      />
                      <span class="label">
                        <span v-html="t.mail_address"></span>
                      </span>
                      <span class="border"></span>
                    </label>
                  </div>
                  <span v-for="err in errors.email" class="inp_error">{{
                    err
                  }}</span>
                </div>
                <div class="mt-3">
                    <label>
                      <span class="d-block help_text" v-html="t.affiliated_code" />
                      <input
                        type="text"
                        name="affiliated_to"
                        :value="affiliated_to"
                        style="padding: 6px 2px"
                      >
                    </label>
                    <div class="question_cont" @click="show_modal_affiliate = !show_modal_affiliate">?</div>
                </div>

                <div class="form-check mt-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="check1"
                    name="subscribed_for_notifications"
                  />
                  <label class="form-check-label" for="check1">
                    <span class="help_text">
                      <span v-html="t.subscribe_text"></span>
                    </span>
                  </label>
                </div>

                <div class="form-check mt-2">
                  <input
                    name="pos_tos"
                    type="checkbox"
                    class="form-check-input"
                    id="check2"
                    v-model="checkbox_pos_tos"
                  />
                  <label class="form-check-label" for="check2">
                    <span class="help_text">
                      <span v-html="t.tos_text_1"></span>&nbsp;
                      <router-link :to="{ name: 'privacy_policy' }">
                        <span v-html="t.tos_text_2"></span>
                      </router-link>&nbsp;
                      <span v-html="t.tos_text_3"></span>&nbsp;
                      <router-link :to="{ name: 'tos' }" target="_blank">
                        <span v-html="t.tos_text_4"></span>
                      </router-link>
                    </span>
                  </label>
                  <span
                    v-for="err in errors.checkbox_pos_tos"
                    class="inp_error"
                    >{{ err }}</span
                  >
                </div>

                <div class="text-center mt-4 mb-4">
                  <button class="btn button_slide_white" type="submit">
                    <span v-html="t.continue"></span>
                  </button>
                </div>
              </form>
            </div>
            <hr />
            <div class="mt-3">
              <span class="help_text">
                <span v-html="t.already_have"></span>
                <a href="javascript:void(0)" @click="switchAuthFormType(1)" data-cy="login-link">
                  <span v-html="t.Log_in"></span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :show_modal="show_modal" :closeModal="()=>show_modal=false">
      <div v-html="modal_body"></div>
    </Modal>
    <Modal :show_modal="show_modal_affiliate" :closeModal="()=>show_modal_affiliate=false" width="300">
      <div v-html="t.affiliated_to_modal"></div>
      <button class="btn button_slide_white mt-3" @click="show_modal_affiliate = !show_modal_affiliate">
        <span v-html="t.CLOSE"></span>
      </button>
    </Modal>
    <div class="loader_cont" v-if="loading">
      <LoaderAnim />
    </div>
  </div>
</template>

<script>
import {register} from "@/api/auth"

const t =
  //<i18n>
{
  "en": {
    "left_head": "Access exclusive features with a free account",
    "left_head_landing_page": "Please register to explore your possible land rights",
    "left_p_1": "Get regular updates",
    "left_p_2": "Download your free, personalized report",
    "left_p_3": "Choose whether to work with TA to clean and sell your land, at no upfront cost",
    "register": "REGISTER",
    "name": "Name",
    "last_name": "Last Name",
    "country": "Country",
    "mail_address": "E-mail",
    "affiliated_code": "Affiliate code:",
    "affiliated_to_modal": "If you learned about us via our affiliate, please enter affiliate's code <br> (5 digit code)",
    "CLOSE": "CLOSE",
    "subscribe_text": "Please keep me in the loop with occasional updates on Terra Adriatica's products and services.",
    "tos_text_1": "By creating an account, I agree to Terra Adriatica’s",
    "tos_text_2": "Privacy policy",
    "tos_text_3": "and",
    "tos_text_4": "Terms of Use",
    "continue": "CONTINUE",
    "already_have": "Already have an account?",
    "Log_in": "Log in",
    "tos_error": "Please agree to our Privacy Policy and Terms of Use to continue.",
    "check_mail_1": "Almost there!",
    "check_mail_2": "To finish your registration, please check your mailbox. A confirmation e-mail was sent to your e-mail address.",
    "check_mail_3": "In case you can't find it, please check also in spam/junk folder.",
    "This field is required.": "This field is required.",
    "A user with that email already exists.": "A user with that e-mail already exists."
  },
  "hr": {
    "left_head": "Registrirajte se za pristup dodatnim sadržajima",
    "left_head_landing_page": "Registrirajte se i istražite prava na zemljište u Hrvatskoj",
    "left_p_1": "Unesite obiteljsko stablo",
    "left_p_2": "Preuzmite besplatno, personalizirano izvješće",
    "left_p_3": "Odlučite želite li surađivati s TA da biste očistili i prodali zemljište, bez troškova unaprijed",
    "register": "REGISTRACIJA",
    "name": "Ime",
    "last_name": "Prezime",
    "country": "Država",
    "mail_address": "E-mail adresa",
    "affiliated_code": "Partnerski kod:",
    "affiliated_to_modal": "Ako ste za nas saznali preko našeg Partnera, molimo unesite njegov kod <br> (5 znamenki)",
    "CLOSE": "ZATVORI",
    "subscribe_text": "Molim da me obavještavate o povremenim ažuriranjima proizvoda i usluga Terra Adriatice.",
    "tos_text_1": "Izradom računa prihvaćam",
    "tos_text_2": "Politiku privatnosti",
    "tos_text_3": "i",
    "tos_text_4": "Uvjete korištenja",
    "continue": "NASTAVI",
    "already_have": "Već imate račun?",
    "Log_in": "Prijava",
    "tos_error": "Molimo potvrdite slažete li se s našom Politikom privatnosti i Uvjetima korištenja.",
    "check_mail_1": "Korak ste do cilja!",
    "check_mail_2": "Da biste završili registraciju, molimo Vas da provjerite svoj e-mail. Na Vašu adresu poslan je e-mail potvrde.",
    "check_mail_3": "U slučaju da ga ne možete pronaći, provjerite u spam pretincu.",
    "This field is required.": "Ovo polje je obavezno.",
    "A user with that email already exists.": "E-mail adresa je već postojeća."
  },
  "es": {
    "left_head": "Acceda a funciones exclusivas con una cuenta gratuita",
    "left_head_landing_page": "Regístrese para descubrir qué derechos sobre terrenos podría tener",
    "left_p_1": "Rellene su árbol genealógico",
    "left_p_2": "Obtenga un informe personalizado gratuito",
    "left_p_3": "Decide si quieren cooperar con TA para limpiar su título y vender el terreno, sin coste inicial",
    "register": "REGÍSTRESE",
    "name": "Nombre",
    "last_name": "Apellido",
    "country": "País",
    "mail_address": "Dirección de correo electrónico",
    "subscribe_text": "Por favor, manténganme informado sobre actualizaciones periódicas de los productos y servicios de Terra Adriatica.",
    "tos_text_1": "Al crear una cuenta, acepto la",
    "tos_text_2": "Política de Privacidad",
    "tos_text_3": "y",
    "tos_text_4": "los Términos y Condiciones de Terra Adriatica",
    "continue": "CONTINUAR",
    "already_have": "¿Ya tiene una cuenta?",
    "Log_in": "Iniciar sesión",
    "tos_error": "Por favor, acepte nuestra Política de Privacidad y los Términos y Condiciones para continuar.",
    "check_mail_1": "¡Casi, casi!",
    "check_mail_2": "Para finalizar su registro, por favor, compruebe su buzón. Le mandamos un correo electrónico de confirmación.",
    "check_mail_3": "En caso de que no pueda encontrarlo, también compruebe su carpeta de correo no deseado (spam/junk).",
    "This field is required.": "Este campo es obligatorio.",
    "A user with that email already exists.": "Ya existe un usuario con esta dirección de correo electrónico."
  }
}
//</i18n>

import Modal from "@/components/Elements/Modal";
import countries from "@/assets/other/countries.json";
import LoaderAnim from "@/components/Elements/loader-anim"

export default {
  name: "Registration",
  components: {LoaderAnim, Modal },
  data() {
    return {
      t: t[this.$lang],
      show_modal: false,
      modal_body: "",
      checkbox_pos_tos: false,
      account_created: false,
      is_landing_page: (this.$route.meta && this.$route.meta.is_landing_page),
      affiliated_to: (document.cookie.split('; ').find(row => row.startsWith('affiliated_to')) || '').replace('affiliated_to=', ''),
      show_modal_affiliate: false,
      loading: false,

      countries: countries,
      errors: {
        first_name: [],
        last_name: [],
        country_of_residence: [],
        email: [],
        checkbox_pos_tos: []
      }
    };
  },
  watch: {
    show_modal() {
      const vm = this;
      if (!vm.show_modal && vm.account_created)
        vm.$store.dispatch("auth/changeModalStatus", false);
    }
  },
  methods: {
    onFormFieldChange(el) {
      const vm = this;

      const field_name = el.name;
      vm.errors[field_name] = [];

      function classed(el, class_name, add_class) {
        const re = new RegExp("(?:^|\\s)" + class_name + "(?!\\S)", "g");
        if (add_class && !el.className.match(re))
          el.className += " " + class_name;
        else if (!add_class) el.className = el.className.replace(re, "");
      }

      const value = el.value;
      classed(el, "has_value", value !== "");
    },
    register(event) {
      const vm = this;

      event.preventDefault();

      if (!vm.checkbox_pos_tos) {
        vm.errors.checkbox_pos_tos = [vm.t.tos_error];
        return;
      }

      vm.loading = true;
      register(event).then(response => {
        response.json().then(data => {
          if (response.status === 200) success(data)
          else if (response.status === 400) noSuccess(data)
        });
      }).finally(() => vm.loading = false)

      function success(data) {
        vm.modal_body = (`
          <div data-cy="successful_reg_msg">
            <h4 class='font-weight-normal mb-3'>${vm.t.check_mail_1}</h4>
            <p>${vm.t.check_mail_2}</p>
            <p>${vm.t.check_mail_3}</p>
          </div>
        `);
        vm.show_modal = true;
        vm.account_created = true;
        window.history.pushState({}, "welcome", "/welcome")

        window.registration_mail = data.mail  // testing
      }

      function noSuccess(data) {
        vm.modal_body = "";
        Object.keys(vm.errors).forEach(k => (vm.errors[k] = []));
        Object.keys(data.errors).forEach(error_type => {
          vm.errors[error_type] = data.errors[error_type].map(
              err_code => vm.t[err_code]
          );
        });
      }
    },
    switchAuthFormType(form_type) {
      const vm = this;
      vm.$store.dispatch("auth/switchFormType", form_type)
    }
  }
};
</script>

<style scoped>
.main_cont {
  width: 800px;
  max-width: 100%;

  border-radius: 10px;
  /*overflow-x: hidden;*/
  /*overflow-y: hidden;*/
}

.side_cont {
  padding: 40px;
  color: white;
}

.reg_cont {
  padding: 40px 60px;
  background-color: white;
  color: rgb(4, 43, 93);
}

h3 {
  font-weight: 800 !important;
}

.side_cont {
  background-position-x: center;
  background-position-y: 39%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
  width: 100%;
}

.no-pr {
  padding-right: 0;
}

.no-pl {
  padding-left: 0;
}

button {
  padding: 10px;
  width: 75%;
  background-color: rgb(4, 43, 93);
  border-color: rgb(4, 43, 93);
  color: #fffff3;
  font-weight: 600;
  vertical-align: bottom;
}

.side_point {
  margin-bottom: 40px;
  margin-left: 0;
  display: flex;
}

.side_point .text {
  width: calc(100% - 60px);
  padding-left: 10px;
}

.side_img_cont {
  pointer-events: none;
  position: relative;
  width: 44px;
}

.col-2 {
  padding: 0;
}

.side_img_cont svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.side_point:nth-child(1) svg {
  width: 42px;
}

.side_point:nth-child(2) svg {
  width: 32px;
}

.side_point:nth-child(3) svg {
  width: 40px;
  margin: 0 auto;
  bottom: initial;
  top: 4px;
}

.text {
  padding-left: 3px;
}

/*.text span {*/
/*  display: inline-block;*/
/*  position: absolute;*/
/*  margin: auto;*/
/*}*/

input[type="checkbox"] {
  width: 13px;
  height: 13px;
  margin-top: 6px;
}

span.caret_down {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px;
  width: 15px;
  z-index: 2;
  pointer-events: none;
}

.help_text {
  font-size: 13px;
}

.inp_error {
  display: block;
  color: red;
  font-size: 11px;
}

select {
  border: none;
  background-color: transparent;
}

select.has_value {
  color: rgb(4, 43, 93);
}

select {
  color: #999;
}

option {
  color: rgb(4, 43, 93);
}

label.form-check-label {
  line-height: 1.2;
}

.question_cont {
  display: inline-block;
  cursor: pointer;
  color: lightgray;
  border: solid lightgray 1px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding-left: 8px;
  margin-left: 10px;
  margin-top: 3px;
}

.loader_cont {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: center;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.2);
  padding-top: calc(43% - 40px);
}

@media (max-width: 576px) {
  .reg_cont {
    padding: 30px 40px;
  }

  .no-pr {
    padding-left: initial;
  }

  .no-pl {
    padding-right: initial;
  }
}
</style>
