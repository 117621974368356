import {apiFetchGet, apiFetchPatch, apiFetchPost} from "@/api/handler"

const state = () => ({
  info: null,
})

const actions = {
  submitInfo({}, form_data) {
    return apiFetchPatch("/api/portal/profile-info", form_data)
  },
  changeInfoPartially({commit}, {key, value}) {
    return commit("mutateInfoPartially", {key, value})
  },
  getInfo({commit, dispatch}) {
    return apiFetchGet("/api/portal/profile-info")
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else throw "bad response";
      })
      .then(data => {
        commit("mutateInfo", data)
        dispatch("free_report/updateState", data.reports.free_report, {root: true})
        dispatch("premium_report/updateState", data.reports.premium_report, {root: true})
      })
      .catch(reason => console.log(reason));
  },
  registerLocksmith({dispatch}) {
    return apiFetchPost("/api/portal/locksmith")
      .then(response => {if (response.status !== 200) throw "api rejects the call"})
      .catch(reason => alert(reason))
      .finally(() => dispatch("getInfo"));
  },
  changeProfileLang({dispatch}, lang) {
    const form_data = new FormData();
    form_data.append("language", lang);
    dispatch("submitInfo", form_data)
  },
}

const getters = {}

const mutations = {
  mutateInfo(state, new_value) {
    state.info = new_value;
  },
  mutateInfoPartially(state, {key, value}) {
    state.info[key] = value;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
