const window_break_points = {xs: 0, sm: 576, md: 768, lg: 992, xl: 1200}

const state = () => ({
  window_width: window.innerWidth,
  window_width_state: null
})

const mutations = {
  mutateWindowWidth(state, value) {state.window_width = value},
  mutateWindowWidthState(state, value) {state.window_width_state = value},
}

const getters = {
  isWindowStateLessThen(state) {
    return cls => state.window_width < window_break_points[cls]
  },
  isWindowStateMoreThen(state) {
    return cls => state.window_width > window_break_points[cls]-1
  }
}

const actions = {
  updateWindowWidth({commit}) {
    const window_width = window.innerWidth
    commit("mutateWindowWidth", window_width)
    commit("mutateWindowWidthState", updateWindowWidthState(window_width))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

function updateWindowWidthState(window_width) {
  let new_window_width_state;
  for (let [k, v] of Object.entries(window_break_points)) {
    new_window_width_state = k;
    if (v > window_width) break;
  }
  return new_window_width_state
}