export default function translate_ca_data(data_ca) {
  const data = {},
    rel_types = ["main", "f", "m", "ff", "fm", "mf", "mm"],
    main = data_ca[0]

  return rel_types.reduce((acc, rel_type) => {acc[rel_type] = getRelSafe(rel_type); return acc}, {})

  function getRelSafe(rel_type) {
    try {const d = getRel(rel_type); return Object.assign({}, d.data, {id: d.id})}
    catch (e) {return {}}
  }

  function getRel(rel_type) {
    if (rel_type === 'main') return main
    if (rel_type === 'f') return data_ca.find(d => d.id === main.rels.father)
    if (rel_type === 'ff') return data_ca.find(d => d.id === data_ca.find(d => d.id === main.rels.father).rels.father)
    if (rel_type === 'fm') return data_ca.find(d => d.id === data_ca.find(d => d.id === main.rels.father).rels.mother)

    if (rel_type === 'm') return data_ca.find(d => d.id === main.rels.mother)
    if (rel_type === 'mf') return data_ca.find(d => d.id === data_ca.find(d => d.id === main.rels.mother).rels.father)
    if (rel_type === 'mm') return data_ca.find(d => d.id === data_ca.find(d => d.id === main.rels.mother).rels.mother)
  }
}

export function createFirstNode(profile_info) {
  const data = {};
  ["first_name", "last_name", "gender", "address_of_residence", "date_of_birth"].forEach(k => data[k] = profile_info[k]);
  if (data.date_of_birth) data.date_of_birth = data.date_of_birth.split("-")[0]
  return {id: f3.handlers.generateUUID(), data, rels: {}}
}
