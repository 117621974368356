<template>
  <div class="image_parallax_wrapper" v-if="!is_nav || $route.meta.is_landing_page">
    <div class="image_parallax" :class="{[cls]: !!cls}" :style="{'position': is_nav ? 'absolute' : null}" />
  </div>
</template>

<script>
export default {
  name: "LandingPageTopParallax",
  props: ["is_nav"],
  data() {
    return {
      url: '',
      bg_pos_y: '',
      cls: ''
    }
  },
  mounted() {
    this.setup()
  },
  methods: {
    setup() {
      const vm = this;

      const curr_path = vm.$route.path,
          curr_path_end = curr_path.split("/")[curr_path.split("/").length - 1]

      if (curr_path_end === "start" || curr_path_end === "landing-page" || curr_path_end === "USA") {
        vm.cls = "landing_page_default"
      } else if (curr_path_end.toLowerCase() === "got") {
        vm.cls = "landing_page_got_dubrovnik"
      } else if (curr_path_end === "search") {
        vm.cls = "landing_page_dubrovnik_usa"
      }

    }
  }
}
</script>

<style scoped>
.image_parallax_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image_parallax {
  position: fixed;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;

  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.landing_page_default {
  background-image: url("/static/images/LandingPageCover.jpg");
  background-position-y: 50%;
}

.landing_page_got_dubrovnik {
  background-image: url("/static/images/GOT-dubrovnik.jpg");
  background-position-y: 0%;
}

.landing_page_dubrovnik_usa {
  background-image: url("/static/images/Dubrovnik-za-Naslovnu-USA.jpg");
}

@media (min-height: 1000px) {
  .landing_page_dubrovnik_usa {
    top: -100px;
  }
}

@media (min-height: 1200px) {
  .landing_page_dubrovnik_usa {
    top: -170px;
  }
}
</style>